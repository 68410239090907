import { tagsConstants } from '../constants';
import { tagsService } from '../services';

export const tagsActions = {
  get,
};

function get() {
  return dispatch => {
    dispatch(request());
    tagsService.get().then(
      data => dispatch(success(data.data)),
      error => dispatch(failure(error.error))
    );
  };

  function request() {
    return { type: tagsConstants.GET_REQUEST };
  }
  function success(tags) {
    return { type: tagsConstants.GET_SUCCESS, tags };
  }
  function failure(error) {
    return { type: tagsConstants.GET_FAILURE, error };
  }
}
