import { get_from_store } from '../reducers/authentication.reducer';

export function authHeader() {
  // return authorization header with jwt token
  let token = get_from_store('state.authentication.token');

  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}
