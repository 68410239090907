import { featuresConstants } from '../constants';

const initialState = {
  loading: false,
  item: null,
  items: [],
  saved: true,
  is_saving: false,
  error: false,
  error_message: '',
};

export function features(state = initialState, action) {
  switch (action.type) {
    case featuresConstants.GET_REQUEST:
      return {
        ...state,
        item: null,
        loading: true,
      };
    case featuresConstants.GET_SUCCESS:
    {
      const data = action.data.data;
      return {
        ...state,
        item: data,
        loading: false,
      };
    }
    case featuresConstants.GET_FAILURE:
      return {
        ...state,
        item: null,
        loading: false,
        error: true,
        error_message: action.message,
        // later add error message here
      };

    case featuresConstants.UPDATE_REQUEST:
      return {
        ...state,
        is_saving: true,
        saved: false,
        error: false,
        error_message: '',
      };

    case featuresConstants.UPDATE_FAILURE:
      return {
        ...state,
        is_saving: false,
        saved: false,
        error: true,
        error_message: action.message,
      };

    case featuresConstants.ALL_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
        error: false,
        error_message: '',
      };
    case featuresConstants.UPDATE_SUCCESS:
    case featuresConstants.ALL_SUCCESS: {
      let convertSettings = action.data.data.map((item) => {
        return { ...item, settings: JSON.parse(item.settings) };
      });

      return {
        ...state,
        items: [...state.items, ...convertSettings],
        loading: false,
        error: false,
        error_message: '',
      };
    }
    case featuresConstants.ALL_FAILURE:
      return {
        ...state,
        items: [],
        loading: false,
        error: true,
        error_message: action.message,
      };

    default:
      return state;
  }
}
