import defaults from './default.actions';

import { userConstants } from '../constants';
import { userService } from '../services';

import { history } from '../helpers';
import { alertActions } from '.';
import { modalActions } from './modal.actions';

const service_constant = {
  service: userService,
  constants: userConstants,
};

export const userActions = {
  getAll,
  login,
  logout,
  refreshToken,
  impersonateAs,
  getCurrentUser,
  changeOwnPassword,
  showForm,
  get,
  get_license_statistic,
  changePassword,
  forgetPassword,
  checkRecoveryToken,
  changePasswordWithToken,
  update,
  showNextForm,
  showNewForm,
  create,
  remove,
  disableWelcomeModal,
  getOrganisations,
};

function get_license_statistic(id) {
  return (dispatch) => {
    dispatch(request());

    userService.get_license_statistic(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_LICENSE_STATISTIC_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_LICENSE_STATISTIC_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GET_LICENSE_STATISTIC_FAILURE, error };
  }
}

function get(id) {
  return defaults.get({
    id,
    ...service_constant,
  });
}

function remove(id) {
  return (dispatch) => {
    dispatch(
      modalActions.confirmRemoveUser(() => {
        dispatch(
          defaults.remove({
            id,
            success_message: 'Benutzer wurde gelöscht.',
            error_message: 'Benutzer konnte nicht gelöscht werden.',
            ...service_constant,
          })
        );
      })
    );
  };
}

function getAll(options) {
  return defaults.pagination({
    ...service_constant,
    ...options,
  });
}

function showNextForm(id) {
  return (dispatch) => {
    dispatch({ type: userConstants.OPEN_FORM_SAGA, id });
  };
}

function showForm(id) {
  return defaults.show_side_form({
    id,
    name: 'USER_FORM',
  });
}

function showNewForm() {
  return (dispatch) => {
    dispatch({ type: userConstants.OPEN_FORM_SAGA, id: 'new' });
  };
}

function create(form) {
  return (dispatch) => {
    dispatch({ type: userConstants.CREATE_REQUEST, form });
  };
}

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password).then(
      (data) => {
        dispatch(success(data));
        history.push('/');
      },
      (error) => {
        dispatch(failure(error.error));
      }
    );
  };

  function request(data) {
    return { type: userConstants.LOGIN_REQUEST, data };
  }
  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function refreshToken(dispatch) {
  var freshTokenPromise = userService
    .fetchJWTToken()
    .then((t) => {
      dispatch({
        type: 'DONE_REFRESHING_TOKEN',
        token: t.token,
      });

      return t.token
        ? Promise.resolve(t.token)
        : Promise.reject({
          message: 'could not refresh token',
        });
    })
    .catch((e) => {
      dispatch({
        type: 'ERROR_REFRESHING_TOKEN',
        error: `${e.error ? (e.error.message ? e.error.message : e.error) : e}`,
      });
      return Promise.reject(e);
    });

  dispatch({
    type: 'REFRESHING_TOKEN',

    // we want to keep track of token promise in the state so that we don't try to refresh
    // the token again while refreshing is in process
    freshTokenPromise,
  });

  return freshTokenPromise;
}

//impersonate as a specific user.. only works as admin
function getCurrentUser() {
  return (dispatch) => {
    dispatch(request());

    userService.getCurrentUser().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.error) {
          if (error.error.message) {
            dispatch(
              alertActions.error(
                `${error.error.message || error.error}`,
                'Fehler'
              )
            );
          } else {
            dispatch(alertActions.error(`${error.error}`, 'Fehler'));
          }
        } else {
          dispatch(alertActions.error(`${error}`, 'Fehler'));
        }

        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GET_FAILURE, error };
  }
}

//impersonate as a specific user.. only works as admin
function impersonateAs(userID) {
  return (dispatch) => {
    dispatch(request({ userID }));

    userService.impersonate(userID).then(
      (data) => {
        dispatch(success(data));
        history.push('/');
      },
      (error) => {
        dispatch(
          alertActions.error(`${error.error.message || error.error}`, 'Fehler')
        );
        dispatch(failure(error.error));
      }
    );
  };

  function request(data) {
    return { type: userConstants.IMPERSONATE_REQUEST, data };
  }
  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.IMPERSONATE_FAILURE, error };
  }
}

function changeOwnPassword(old_password, new_password) {
  return (dispatch) => {
    dispatch(request({}));

    userService.changeOwnPassword(old_password, new_password).then(
      (data) => {
        dispatch(
          alertActions.success(
            'Das Kennwort wurde erfolgreich geändert',
            'Kennwort geändert'
          )
        );
        dispatch(success(data));
      },
      (error) => {
        dispatch(
          alertActions.error(`${error.error.message || error.error}`, 'Fehler')
        );
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_OWN_PASSWORD_REQUEST };
  }
  function success(data) {
    return { type: userConstants.CHANGE_OWN_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_OWN_PASSWORD_FAILURE, error };
  }
}

function changePassword(
  id,
  send_email,
  show_password,
  old_password,
  new_password
) {
  return (dispatch) => {
    dispatch(request({}));

    userService
      .changePassword(id, send_email, show_password, old_password, new_password)
      .then(
        (data) => {
          dispatch(
            alertActions.success(
              'Das Kennwort wurde erfolgreich geändert',
              'Kennwort geändert'
            )
          );
          dispatch(success(data));
        },
        (error) => {
          dispatch(
            alertActions.error(
              `${error.error.message || error.error}`,
              'Fehler'
            )
          );
          dispatch(failure(error.error));
        }
      );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success(data) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function forgetPassword(email) {
  return (dispatch) => {
    dispatch(request({}));

    userService.forgetPassword(email).then(
      () => {
        dispatch(modalActions.confirmPasswordReset(email));
      },
      () => {
        dispatch(modalActions.confirmPasswordReset(email));
      }
    );
  };

  function request() {
    return { type: userConstants.FORGET_PASSWORD_REQUEST };
  }
}

function checkRecoveryToken(token) {
  return (dispatch) => {
    dispatch(request({}));

    userService.checkRecoveryToken(token).then(
      () => {
        dispatch(success());
      },
      () => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: userConstants.CHECK_TOKEN_REQUEST };
  }
  function success() {
    return { type: userConstants.CHECK_TOKEN_SUCCESS };
  }
  function failure() {
    return { type: userConstants.CHECK_TOKEN_FAILURE };
  }
}

function changePasswordWithToken(token, password) {
  return (dispatch) => {
    dispatch(request({}));

    userService.changePasswordWithToken(token, password).then(
      (data) => {
        dispatch(
          alertActions.success(
            'Das Kennwort wurde erfolgreich geändert',
            'Kennwort geändert'
          )
        );
        dispatch(success(data));
      },
      (error) => {
        dispatch(
          alertActions.error(`${error.error.message || error.error}`, 'Fehler')
        );
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success(data) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function update(form) {
  return defaults.update({
    form,

    ...service_constant,
    success_message: 'Änderungen wurden gespeichert.',
    error_message: 'Änderungen konnten nicht gespeichert werden.',
  });
}

function disableWelcomeModal(id) {
  return (dispatch) => {
    userService.updateWelcomeModal(id, false).then(
      () => {
        dispatch({
          type: userConstants.UPDATE_WELCOME_SUCCESS,
          show: false,
        });
      },
      (_error) => {
        dispatch(
          alertActions.error(
            'Konnte den Status der Willkommensmeldung nicht updaten',
            'Fehler'
          )
        );
      }
    );
  };
}

function getOrganisations() {
  return (dispatch) => {
    dispatch(request({}));

    userService.getOrganisations().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_ORGANISATIONS_REQUEST };
  }
  function success(data) {
    return { type: userConstants.GET_ORGANISATIONS_SUCCESS, data };
  }
  function failure(error) {
    return { type: userConstants.GET_ORGANISATIONS_FAILURE, error };
  }
}
