import { jwtDecode } from 'jwt-js-decode';
import moment from 'moment';
import { userActions } from '../actions';

export function jwt({ dispatch, getState }) {
  return (next) => (action) => {
    if (
      action &&
      action.type &&
      (action.type.startsWith('USERS_LOGOUT') ||
        action.type.startsWith('USERS_LOGIN_'))
    ) {
      return next(action);
    }

    // at first, check on every action the token expire date...
    if (
      getState().authentication &&
      getState().authentication.token &&
      getState().authentication.loggedIn === true
    ) {
      const decoded_token = jwtDecode(getState().authentication.token).payload;
      if (decoded_token && decoded_token.exp) {
        const tokenExpiration = decoded_token.exp;
        const expireDate = moment.unix(tokenExpiration);
        if (expireDate - moment(Date.now()) < 0) {
          return dispatch(userActions.logout());
        }
      }
    }
    
    // only worry about expiring token for async actions
    if (typeof action === 'function') {
      if (getState().authentication && getState().authentication.token) {
        // decode jwt so that we know if and when it expires
        const decoded_token = jwtDecode(getState().authentication.token)
          .payload;

        if (decoded_token && decoded_token.exp) {
          const tokenExpiration = decoded_token.exp;
          const expireDate = moment.unix(tokenExpiration);
          //console.info("CHECK IF TOKEN IS ABOUT TO EXPIRE!", expireDate.format(), expireDate - moment(Date.now()) );
          if (tokenExpiration && expireDate - moment(Date.now()) < 1400000) {
            // is about to expire in 60 seconds...
            // make sure we are not already refreshing the token

            if (!getState().authentication.freshTokenPromise) {
              return userActions
                .refreshToken(dispatch)
                .then(() => next(action));
            } else {
              return getState().authentication.freshTokenPromise.then(() =>
                next(action)
              );
            }
          }
        }
      }
    }
    return next(action);
  };
}
