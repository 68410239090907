export const navigationConstants = {
  TOGGLE_USER_MENU: 'NAV_TOGGLE_USER_MENU',
  TOGGLE_LEFT_SIDE_NAV_MENU: 'NAV_TOGGLE_LEFT_SIDE_NAV_MENU',
  HIDE_ALL_MENUS: 'NAV_HIDE_ALL_MENUES',
  HIDE_USER_MENU: 'NAV_HIDE_USER_MENU',
  HIDE_LEFT_SIDE_NAV_MENU: 'NAV_HIDE_LEFT_SIDE_NAV_MENU',
  SHOW_LEFT_SIDE_NAV_MENU: 'NAV_SHOW_LEFT_SIDE_NAV_MENU',

  // navigation for submenus admin && profile,
  ADMIN_GOTO: 'NAV_SUB_ADMIN_GOTO',

  PROFILE_GOTO: 'NAV_SUB_PROFILE_GOTO',
};
