import defaults from './default.constants';

export const elementsConstants = {
  ...defaults.constants('elements'),
  ALL_REQUEST_SAGA: 'ELEMENTS_ALL_REQUEST_SAGA',

  UPLOAD_REQUEST: 'ELEMENTS_UPLOAD_REQUEST',
  UPLOAD_PROGRESS: 'ELEMENTS_UPLOAD_PROGRESS',
  UPLOAD_SUCCESS: 'ELEMENTS_UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'ELEMENTS_UPLOAD_FAILURE',

  ...defaults.rsf('new', 'elements'),
  ...defaults.rsf('create', 'elements'),

  ...defaults.list_size('elements'),

  SHOW_FORM: 'ELEMENTS_SHOW_FORM',
  HIDE_FORM: 'ELEMENTS_HIDE_FORM',

  GET_STATISTICS_SAGA: 'ELEMENTS_GET_STATISTICS_SAGA',
  ...defaults.rsf('get_statistics', 'elements'),
};
