import { locationConstants, deviceConstants } from '../constants';

import defaults from './default.reducer';

const initialState = {
  /* select detail location... */
  selected_id: null,
  selectable_locations: [],
  location_types: [],
  updating_location_types: false,
  searchText: '',
};

const location = defaults.reducer(
  locationConstants,
  initialState,
  (state, action) => {
    switch (action.type) {
      case locationConstants.SELECT_LOCATION:
        return {
          ...state,
          selected_id: action.id,
          item: null,
        };
      case locationConstants.GETFORSELECTION_REQUEST:
        return {
          ...state,
          selectable_locations: [],
          loading: true,
        };
      case locationConstants.GETFORSELECTION_SUCCESS:
        return {
          ...state,
          selectable_locations: action.locations,
          loading: false,
        };
      case locationConstants.GETFORSELECTION_FAILURE:
        return {
          ...state,
          selectable_locations: [],
          loading: false,
        };

      case deviceConstants.ASSIGN_TO_LOCATION_SUCCESS:
        return {
          ...state,
          items: state.items.map((t) => {
            if (t.model.ID === action.device.location_id) {
              let new_devices = [...(t.devices || []), action.device];
              return {
                ...t,
                devices: new_devices,
              };
            } else {
              return t;
            }
          }),
        };
      case deviceConstants.ASSIGN_MIRROR_TO_DEVICE_SUCCESS:
        return {
          ...state,
          items: state.items.map((t) => {
            if (t.model.ID === action.device.location_id) {
              let new_devices = [...(t.devices || []), action.device];
              return {
                ...t,
                devices: new_devices,
              };
            } else {
              return t;
            }
          }),
        };
      case deviceConstants.UPDATE_SUCCESS: {
        return {
          ...state,
          items: state.items.map((t) => {
            if (t.model.ID === action.data.data.location_id) {
              return {
                ...t,
                devices: t.devices.map((x) =>
                  x.model.ID === action.data.data.model.ID
                    ? action.data.data
                    : x
                ),
              };
            } else {
              return t;
            }
          }),
        };
      }

      case deviceConstants.GET_DEVICES_FOR_LOCATION_SUCCESS:
        return {
          ...state,
          items: state.items.map((t) => {
            if (t.model.ID === action.location_id) {
              return {
                ...t,
                devices: action.devices,
              };
            } else {
              return t;
            }
          }),
        };

      case locationConstants.GETLOCATIONTYPES_REQUEST:
        return {
          ...state,
          location_types: [],
          updating_location_types: false,
        };
      case locationConstants.UPDATELOCATIONTYPES_REQUEST:
        return {
          ...state,
          updating_location_types: true,
        };
      case locationConstants.UPDATELOCATIONTYPES_SUCCESS:
      case locationConstants.GETLOCATIONTYPES_SUCCESS:
        return {
          ...state,
          location_types: action.types || [],
          updating_location_types: false,
        };
      case 'DEVICES_REMOVE_SUCCESS':
        console.info('remove successs...');
        return {
          ...state,
          items: state.items.map((t) => {
            return {
              ...t,
              devices: t.devices.filter((x) => x.model.ID !== action.id),
            };
          }),
        };
      case 'USERS_LOGOUT':
        return {
          ...initialState,
        };
      default:
        return state;
    }
  }
);

export { location };
