const initialState = {
  loading: false,
  items: [],
};

/**
 * news items:
 * {
 *      model.ID,
 *      DateTime,
 *      Headline
 *      Content
 * }
 */

export function news(state = initialState, action) {
  switch (action.type) {
    case 'GET_NEWS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GET_NEWS_RESPONSE':
    {
      const data = action.data.data;
      return {
        ...state,
        items: data,
        loading: false,
      };
    }
    case 'GET_NEWS_FAILURE':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
