import React from 'react';
import { Loading, ProgressBar } from '@carbon/react';

const LoadingPage = ({ title = "", progress = 0 }) => {
  console.info("progress::", progress);
  return (
    <div>
      <div className="cds--loading-overlay custom_loading_container">
        <Loading
          active={true}
          withOverlay={false}
          description={title}
        />
        {
          (progress && progress > 0) && (
            <ProgressBar className="custom_progress_bar_size" max={100} value={progress} hideLabel={true}/>
          )
        }
      </div>
    </div>
  )
};

export default LoadingPage;
