import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-js-decode';
import moment from 'moment';

//import { Router } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import LoadingPage from './content/LoadingPage';
import { userActions } from './redux/actions';
//import { history } from './redux/helpers';

const LoggedInRoutes = React.lazy(() =>
  import(/* webpackChunkName: 'LoggedIn' */ './routes_loggedin')
);

const LoggedOutRoutes = React.lazy(() =>
  import(/* webpackChunkName: 'LoggedOut' */ './routes_loggedout')
);

const AdminRoutes = React.lazy(() =>
  import(/* webpackChunkName: 'AdminRoutes' */ './routes_admin')
);

const App = () => {
  const { loggedIn, isAdmin, token, user } = useSelector(
    (s) => s.authentication
  );
  const dispatch = useDispatch();

  //const prevLoggedIn = usePrevious(loggedIn);

  if (loggedIn === true && user.welcome_info === true) {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'SHOW_WELCOME',
      modalProps: {},
    });
  }

  if (token) {
    var decoded_token = jwtDecode(token).payload;
    if (decoded_token && decoded_token.exp) {
      var tokenExpiration = decoded_token.exp;
      var expireDate = moment.unix(tokenExpiration);
      if (expireDate - moment(Date.now()) < 0) {
        dispatch(userActions.logout());
      }
    }
  }

  let routes;
  if (loggedIn === true) {
    if (isAdmin === true) {
      routes = (
        <React.Suspense fallback={<LoadingPage />}>
          <AdminRoutes />
        </React.Suspense>
      );
    } else {
      routes = (
        <React.Suspense fallback={<LoadingPage />}>
          <LoggedInRoutes />
        </React.Suspense>
      );
    }
  } else {
    routes = (
      <React.Suspense fallback={<LoadingPage />}>
        <LoggedOutRoutes />
      </React.Suspense>
    );
  }

  return (
    <Router
      basename={process.env.REACT_APP_BASENAME || ''}
      forceRefresh={false}
    >
      {routes}
    </Router>
  );
};

export default App;
