import { settingsConstants } from '../constants';
import { settingsService } from '../services';

import defaults from './default.actions';

const settings_constant = {
  service: settingsService,
  constants: settingsConstants,
};

function get(key) {
  return defaults.get({
    id: key,
    ...settings_constant,
  });
}

function update(key, settings) {
  //let form = prepare({ ...pre_form });
  //let e = validate(form);
  /*if (e) {
        return dispatch => {
        dispatch(alertActions.error(e, 'Stream kann nicht gespeichert werden:'));
        };
    }*/

  return defaults.update({
    form: { key: key, settings: settings },
    ...settings_constant,
    success_message: 'Einstellungen wurden gespeichert.',
    error_message: 'Einstellung konnten nicht gespeichert werden.',
  });
}

export const settingsActions = {
  get,
  update,
};
