import { statusConstants } from '../constants';
import { statusService } from '../services';

import defaults from './default.actions';

const service_constant = {
  service: statusService,
  constants: statusConstants,
};

function get() {
  return defaults.get({
    ...service_constant,
  });
}

export const statusActions = {
  get,
};
