import { streamsConstants } from '../constants';
import { streamsService } from '../services';
//import { alertActions } from './alert.actions';
//import { modalActions } from './modal.actions';

import defaults from './default.actions';
//import { history } from '../helpers';
import { alertActions } from './alert.actions';

const service_constant = {
  service: streamsService,
  constants: streamsConstants,
};

export const streamsActions = {
  get,
  update,
  getAll,
  remove,
  selectStream,
  selectElement,
  new_method,
  create,
  showItemForm,
  pasteElements,
};

function selectElement(onSelectElements, streamId = "") {
  return (dispatch) => {

    let sid = streamId;
    if (sid == "new") {
      sid = "0";
    }
    sid = parseInt(sid);

    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'SELECT_ELEMENT',
      modalProps: { onSelectElements, streamId: sid },
    });
  };
}

function showItemForm(props) {
  return (dispatch) => {
    dispatch(show_element_form(props));
  };

  function show_element_form(props) {
    return {
      type: 'SHOW_FORM',
      formType: 'STREAM_ITEM_FORM',
      formProps: props,
    };
  }
}

function new_method() {
  return defaults.new_method(service_constant);
}

function prepare(pre_form) {
  let form = pre_form;
  let pos = 1;
  form.items = form.items.map((f) => {
    let edited_f = { ...f };
    if (
      edited_f.model &&
      typeof edited_f.model.ID === 'string' &&
      edited_f.model.ID.includes('new_')
    ) {
      edited_f.model = null;
    }

    if (edited_f.element_type === "stream") {
      edited_f.stream_element = edited_f.element;
      edited_f.element = null;
    }

    edited_f.position = pos;
    pos += 1;
    return edited_f;
  });
  return form;
}

function create(pre_form) {
  // fix some errors... new eleemts in items. remove id if begins with new_

  let form = prepare({ ...pre_form });
  let e = validate(form);
  if (e) {
    return (dispatch) => {
      dispatch(alertActions.error(e, 'Stream kann nicht gespeichert werden:'));
    };
  }

  return defaults.create({
    form,
    success_message: 'Stream wurde erfolgreich gespeichert!',
    ...service_constant,
  });
}

function get(id) {
  return defaults.get({
    id,
    ...service_constant,
  });
}

function validate(form) {
  let errors = [];

  if (!form.items || form.items.length < 1) {
    errors.push('Es wird mindestens 1 Elemente benötigt.');
  }

  let current_orientation = form.orientation;

  if (
    form.items.find((i) => {
      if (i.element_type === 'stream') {
        return i.stream_element.orientation === 2
          ? false
          : i.stream_element.orientation !== current_orientation;
      } else {
        return i.element.orientation === 2
          ? false
          : i.element.orientation !== current_orientation;
      }
    })
  ) {
    errors.push(
      'Die eingestellte Orientierung steht im Konflikt mit einem Element im Stream!'
    );
  }

  if (!form.name || form.name.length < 2) {
    errors.push('Es muss ein Name angegeben werden');
  }

  return errors.length < 1 ? null : errors.join(' und ');
}

function update(pre_form) {
  let form = prepare({ ...pre_form });
  console.info("form prepared", form);
  let e = validate(form);
  if (e) {
    return (dispatch) => {
      dispatch(alertActions.error(e, 'Stream kann nicht gespeichert werden:'));
    };
  }

  console.info("short before update", form);
  console.info(JSON.stringify(form));
  return defaults.update({
    form,
    ...service_constant,
    success_message: 'Stream wurden gespeichert.',
    error_message: 'Stream konnten nicht gespeichert werden.',
  });
}

function remove(id) {
  return defaults.remove({
    id,
    ...service_constant,
    success_message: 'Stream wurde gelöscht.',
    error_message: 'Stream konnte nicht gelöscht werden.',
  });
}

function getAll(skip = 0, limit = 4, page = 1, filter = {}) {
  return defaults.all({
    ...service_constant,
    skip,
    limit,
    page,
    filter,
  });
}

function selectStream(onSelectStream) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'SELECT_STREAM',
      modalProps: { onSelectStream },
    });
  };
}

function pasteElements(elements, streams) {
  return (dispatch) => {
    dispatch({
      type: 'STREAMS_PASTE_ELEMENTS_SAGA',
      payload: { elements, streams },
    });
  };
}
