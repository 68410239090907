import { takeLatest, put, delay, all } from 'redux-saga/effects';
import { alertActions } from '../actions';
import { userConstants } from '../constants';
import { userService } from '../services';

function* showNextForm(action) {
  yield put({
    type: 'HIDE_FORM',
  });

  yield delay(500);

  if (action.id === 'new') {
    yield put({
      type: userConstants.RESET_ITEM,
    });
  }

  yield put({
    type: 'SHOW_FORM',
    formType: 'USER_FORM',
    formProps: { id: action.id },
  });
}

function* createUser(action) {
  const form = action.form;

  // erstelle einen neuen user.. checke hier clientseitig nichts, das sollte der server machen
  try {
    const data = yield userService.create(form);
    yield put({ type: userConstants.CREATE_SUCCESS, data });
    yield put(alertActions.success('Benutzer wurde erfolgreich angelegt.'));

    yield put({
      type: 'HIDE_FORM',
    });

    yield put({
      type: 'SHOW_FORM',
      formType: 'USER_FORM',
      formProps: { id: data.data.model.ID },
    });
  } catch (error) {
    if (error.error) {
      try {
        let ff = error.error
          .replaceAll("'", '"')
          .replaceAll(', "', ',"')
          .replaceAll('": ', '":');
        let is = JSON.parse(ff);
        if (is.msg) {
          yield put(alertActions.error(is.msg));
          yield put({
            type: userConstants.CREATE_FAILURE,
            error: is.msg,
            error_code: is.code,
            error_type: is.type || '',
          });
        } else {
          yield put(alertActions.error(error.error));
          yield put({ type: userConstants.CREATE_FAILURE, error });
        }
      } catch (error) {
        console.info('got a undefined error', error);
      }
    } else {
      yield put(alertActions.error(error.error));
      yield put({ type: userConstants.CREATE_FAILURE, error });
    }
  }
}

export function* users() {
  yield all([
    yield takeLatest(userConstants.OPEN_FORM_SAGA, showNextForm),
    yield takeLatest(userConstants.CREATE_REQUEST, createUser),
  ]);
}
