import { sidebarConstants, userConstants } from '../constants';

let navigation = JSON.parse(localStorage.getItem('navigation'));
const initialState = {
  menuVisible: false,
  navItems: navigation ? navigation.roots : [],
};

export function sidebar(state = initialState, action) {
  switch (action.type) {
    case sidebarConstants.CHANGE:
      return state;
    case sidebarConstants.TOGGLE_SIDEBAR:
      return { ...state, menuVisible: !state.menuVisible};

    case sidebarConstants.TOGGLE_SIDEBAR_DROPDOWN:
    {
      const newState = { ...state };
      let item = action.item;
      let navGroupIdx = null;
      let navItemIdx = null;

      newState.navItems.forEach((navItem, _idx) => {
        const __idx = navItem.items.indexOf(item);
        if (__idx !== -1) {
          navGroupIdx = _idx;
          navItemIdx = __idx;
        }
      });

      newState.navItems[navGroupIdx].items[navItemIdx].open = !newState.navItems[
        navGroupIdx
      ].items[navItemIdx].open;

      newState.navItems = newState.navItems.map(i => {
        i.items = i.items.map((_i, idx) => {
          if (idx !== navItemIdx) {
            _i.open = false;
          }
          return _i;
        });
        return i;
      });

      return newState;
    }

    case sidebarConstants.GETNAVIGATION_FAILURE:
      return initialState;
    case sidebarConstants.GETNAVIGATION_REQUEST:
      return {...state, navItems: []};
    case sidebarConstants.GETNAVIGATION_SUCCESS:
      //console.info("action data:", action.navigation);
      return {...state, navItems: action.navigation.roots};
    case userConstants.USERS_LOGOUT:
      return initialState;
    default:
      return state
  }
}
