import { deviceConstants, locationConstants } from '../constants';

import defaults from './default.reducer';

const initialState = {
  checking_key: false,
  checking_key_failure: false,
  checking_key_failure_message: '',
  assign_error: false,
  assign_error_message: '',
  features: [],
  mirrors: [],
};

const device = defaults.reducer(
  deviceConstants,
  initialState,
  (state, action) => {
    switch (action.type) {
      case deviceConstants.CHECK_KEY_REQUEST:
        return {
          ...state,
          //item: initialState.item,
          checking_key: true,
          checking_key_failure: initialState.checking_key_failure,
          checking_key_failure_message:
            initialState.checking_key_failure_message,
        };
      case deviceConstants.CHECK_KEY_SUCCESS:
        return {
          ...state,
          //item: action.device,
          checking_key: false,
          checking_key_failure: initialState.checking_key_failure,
          checking_key_failure_message:
            initialState.checking_key_failure_message,
        };
      case deviceConstants.CHECK_KEY_FAILURE:
        return {
          ...state,
          //item: initialState.item,
          checking_key: false,
          checking_key_failure: true,
          checking_key_failure_message: action.message,
        };
      case deviceConstants.CLEAR_STATE:
        return {
          ...state,
          item: initialState.item,
          checking_key: false,
          checking_key_failure: initialState.checking_key_failure,
          checking_key_failure_message:
            initialState.checking_key_failure_message,
        };
      case deviceConstants.ASSIGN_TO_LOCATION_REQUEST:
        return {
          ...state,
          loading: true,
          assign_error: false,
          assign_error_message: '',
        };
      case deviceConstants.ASSIGN_TO_LOCATION_SUCCESS:
        return {
          ...state,
          loading: false,
          assign_error: false,
          assign_error_message: '',
          item: action.device,
          statistic_count: null,
          items: [...state.items, action.device],
        };
      case deviceConstants.ASSIGN_TO_LOCATION_FAILURE:
        return {
          ...state,
          loading: false,
          assign_error: true,
          assign_error_message: action.message,
        };

      case deviceConstants.ASSIGN_MIRROR_TO_DEVICE_REQUEST:
        return {
          ...state,
          loading: true,
          assign_error: false,
          assign_error_message: '',
        };
      case deviceConstants.ASSIGN_MIRROR_TO_DEVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          assign_error: false,
          assign_error_message: '',
          //item: action.device,
          statistic_count: null,
        };
      case deviceConstants.ASSIGN_MIRROR_TO_DEVICE_FAILURE:
        return {
          ...state,
          loading: false,
          assign_error: true,
          assign_error_message: action.message,
        };
      case deviceConstants.GET_SUCCESS:{
        let item = action.data.data;
        if (item) {
          if (!(item.settings && typeof item.settings == 'object')) {
            let obj = JSON.parse(item.settings || '{}');
            obj.show_clock = obj.show_clock == 0 ? false : true;
            obj.show_scrolltext = obj.show_scrolltext == 0 ? false : true;
            item.settings = obj;
          }
        }

        return {
          ...state,
          item: item,
          items: state.items.map((i) =>
            i.model.ID === item.model.ID ? item : i
          ),
          features: action.data.features || [],
          mirrors: action.data.mirrors || [],
          loading: false,
        };
      }
      case deviceConstants.DELETE_SUCCESS:
        return {
          ...state,
          statistic_count: null,
        };
      case deviceConstants.GET_STATISTICS_SUCCESS: {
        return {
          ...state,
          statistic_count: action.data.count,
        };
      }
      case locationConstants.PAGINATION_SUCCESS: {
        const alldevices = [
          ...action.data.data.records.map((r) => r.devices),
        ].flat();
        if (action.data.data.records && action.data.data.prev_page) {
          return {
            ...state,
            items: alldevices,
          };
        } else {
          return {
            ...state,
            items: [...state.items, ...alldevices],
          };
        }
      }
      case deviceConstants.REFRESH_MEM_SUCCESS: {
        let item = state.items.find((i) => i.model.ID === action.data.id);

        if (item) {
          //console.info("find this item:", item);

          item.mem_total = action.data.mem_total;
          item.mem_free = action.data.mem_free;
          item.mem_available = action.data.mem_available;
          item.last_connection = action.data.last_connection;

          item.network = [];

          //add mender informations if available
          for (var key in action.data) {
            if (key.includes('mender_network_')) {
              let network_item = [];
              network_item['name'] = key.replace('mender_network_', '');
              network_item['ip'] = action.data[key];
              item.network.push(network_item);
            }
          }

          const items = state.items.map((i) =>
            i.model.ID === action.data.id ? { ...i, ...item } : i
          );

          //console.info("items:", items.map(i => i.last_connection));
          return {
            ...state,
            items: items,
          };
        }

        return {
          ...state,
        };
      }
      default:
        return state;
    }
  }
);

export { device };
