const initialState = {
  formType: null,
  formProps: {},
};

export function sideForms(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_FORM':
      return {
        formType: action.formType,
        formProps: action.formProps,
      };
    case 'HIDE_FORM':
      return initialState;
    case 'USERS_LOGOUT':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
