import { tagsConstants, elementsConstants } from '../constants';

const initialState = {
  items: [],
  loading: false,
};

export function tags(state = initialState, action) {
  switch (action.type) {
    case tagsConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case tagsConstants.GET_SUCCESS:
      return {
        ...state,
        items: action.tags,
        loading: false,
      };
    case tagsConstants.GET_FAILURE:
      return {
        ...state,
        items: [],
        loading: false,
      };
    case elementsConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: action.data.tags,
      };
    case elementsConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: action.data.tags,
      };
    case 'USERS_LOGOUT':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
