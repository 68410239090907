import { alertActions } from '.';
import { modalActions } from '.';
import { history } from '../helpers';

const get = ({ id, service, constants, before_hook = () => {} }) => {
  return (dispatch) => {
    before_hook();
    dispatch(request());
    service.get(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        switch (error.status) {
          case 404:
            history.replace('/404');
            break;
          case 403:
            dispatch(
              alertActions.error(
                'Sie haben nicht die Erlaubnis die Daten anzuzeigen'
              )
            );
            break;
          default:
            break;
        }
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: constants.GET_REQUEST };
  }
  function success(data) {
    return { type: constants.GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.GET_FAILURE, error };
  }
};

const update = ({
  form,
  service,
  constants,
  success_message,
  error_message,
}) => {
  return (dispatch) => {
    dispatch(request());
    service.update(form).then(
      (data) => {
        dispatch(alertActions.success(success_message || data));
        dispatch(success(data));
      },
      (error) => {
        switch (error.status) {
          case 404:
            dispatch(
              alertActions.error(
                `Es ist ein unbekannter Fehler aufgetreten: ${error.error}`,
                'Fehler bei Update'
              )
            );
            dispatch(failure(error.error));
            break;
          case 403:
            dispatch(
              alertActions.error(
                'Sie haben nicht die Erlaubnis die Daten zu bearbeiten',
                'Fehler bei Update'
              )
            );
            dispatch(failure(error.error));
            break;
          default:
            try {
              let ff = error.error
                .replaceAll('Update Error: ', '')
                .replaceAll("'", '"')
                .replaceAll(', "', ',"')
                .replaceAll('": ', '":');
              console.info(error);
              let is = JSON.parse(ff);
              if (is.msg) {
                dispatch(alertActions.error(is.msg));
                dispatch({
                  type: constants.UPDATE_FAILURE,
                  error: is.msg,
                  error_code: is.code,
                  error_type: is.type || '',
                });
              } else {
                dispatch(alertActions.error(error.error));
                dispatch({ type: constants.UPDATE_FAILURE, error });
              }
            } catch (er) {
              dispatch(
                alertActions.error(error_message || error.error),
                'Fehler bei Update'
              );
              dispatch({ type: constants.UPDATE_FAILURE, error });
              console.info('error:', er);
            }

            break;
        }
      }
    );
  };

  function request() {
    return { type: constants.UPDATE_REQUEST };
  }
  function success(data) {
    return { type: constants.UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.UPDATE_FAILURE, error };
  }
};

const create = ({
  form,
  service,
  constants,
  success_message,
  error_message,
  success_callback = () => {},
}) => {
  return (dispatch) => {
    dispatch(request());
    service.create(form).then(
      (data) => {
        dispatch(alertActions.success(success_message || data));
        dispatch(success(data));
        success_callback(data, dispatch);
      },
      (error) => {
        switch (error.status) {
          case 404:
            dispatch(
              alertActions.error(
                `Es ist ein unbekannter Fehler aufgetreten: ${error.error}`,
                'Fehler bei Erstellung'
              )
            );
            break;
          case 403:
            dispatch(
              alertActions.error(
                'Sie haben nicht die Erlaubnis Daten zu erstellen.',
                'Fehler bei Erstellung'
              )
            );
            break;
          default:
            console.info('FEHLER:', error.error);

            dispatch(
              alertActions.error(error_message || error.error),
              'Fehler bei Erstellung'
            );
            break;
        }
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: constants.CREATE_REQUEST };
  }
  function success(data) {
    return { type: constants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.CREATE_FAILURE, error };
  }
};

const remove = ({
  id,
  service,
  constants,
  success_message,
  error_message,
  with_modal,
  success_callback = () => {},
}) => {
  return (dispatch) => {
    if (with_modal) {
      dispatch(modalActions.hideModal());
    }

    dispatch(request());
    service.remove(id).then(
      (data) => {
        dispatch(alertActions.success(success_message || data));
        dispatch(success(data, id));
        success_callback(dispatch);
      },
      (error) => {
        switch (error.status) {
          case 404:
            dispatch(
              alertActions.error(
                `Es ist ein unbekannter Fehler aufgetreten: ${error.error}`,
                'Fehler beim Entfernen'
              )
            );
            break;
          case 403:
            dispatch(
              alertActions.error(
                'Sie haben nicht die Erlaubnis Daten zu löschen.',
                'Fehler beim Entfernen'
              )
            );
            break;
          default:
            dispatch(
              alertActions.error(error_message || error.error),
              'Fehler beim Entfernen'
            );
            break;
        }
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: constants.REMOVE_REQUEST };
  }
  function success(data, id) {
    return { type: constants.REMOVE_SUCCESS, data, id };
  }
  function failure(error) {
    return { type: constants.REMOVE_FAILURE, error };
  }
};

const all = ({
  service,
  constants,
  skip = 0,
  limit = 10,
  page = 1,
  filter = {},
  after_hook = () => {},
}) => {
  return (dispatch) => {
    dispatch(request(skip === -1, limit, page));
    return service.all(skip === -1 ? 0 : skip, limit, filter).then(
      (data) => {
        after_hook(data.data.total_record > 0);
        return dispatch(success(data));
      },
      (error) => dispatch(failure(error.error))
    );
  };

  function request(reset, limit, page) {
    return { type: constants.ALL_REQUEST, reset, limit, page };
  }
  function success(data) {
    return { type: constants.ALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.ALL_FAILURE, error };
  }
};

const pagination = ({
  service,
  constants,
  pageIndex = 0,
  pageSize = 10,
  sortBy = [],
  filters = [],
  search = null,
}) => {
  return (dispatch) => {
    dispatch(request(pageIndex === -1, pageSize, pageIndex));
    return service
      .pagination(pageIndex, pageSize, sortBy, filters, search)
      .then(
        (data) => {
          return dispatch(success(data));
        },
        (error) => dispatch(failure(error.error))
      );
  };

  function request(reset, limit, page) {
    return { type: constants.PAGINATION_REQUEST, reset, limit, page };
  }
  function success(data) {
    return { type: constants.PAGINATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.PAGINATION_FAILURE, error };
  }
};

const new_method = ({ constants }) => {
  return (dispatch) => {
    dispatch({ type: constants.NEW_REQUEST });
  };
};

const show_side_form = ({ id, name }) => {
  return (dispatch) => {
    dispatch(show_form(id));
  };

  function show_form(id) {
    return { type: 'SHOW_FORM', formType: name, formProps: { id } };
  }
};

const set_list_size = ({ constants, size = 'normal' }) => {
  return (dispatch) => {
    dispatch({
      type: constants.LIST_SIZE_CHANGE,
      size: size,
    });
  };
};

const defaults = {
  get,
  update,
  create,
  remove,
  all,
  pagination,
  new_method,
  show_side_form,
  set_list_size,
};

export default defaults;
