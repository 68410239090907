import { organisationConstants } from '../constants';

const initialState = {
  tableData: [],
  loading: false,
  page: 1,
  pageSize: 5,
  filters: [],
  sortBy: '',
  pages: 0,
  filterData: { records: [], loading: false },
};

export function organisation(state = initialState, action) {
  switch (action.type) {
    case organisationConstants.GETALL_REQUEST:
      return {
        ...state,
        tableData: [],
        loading: true,
      };
    case organisationConstants.GETALL_SUCCESS:
      return {
        ...state,
        tableData: action.organisations.records,
        page: action.organisations.page,
        pages: action.organisations.total_page,
        loading: false,
      };
    case organisationConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case organisationConstants.GETFILTER_REQUEST:
      return {
        ...state,
        filterData: { ...state.filterData, loading: true },
      };
    case organisationConstants.GETFILTER_SUCCESS:
      return {
        ...state,
        filterData: { records: action.records, loading: false },
      };
    case organisationConstants.GETFILTER_FAILURE:
      return {
        ...state,
        filterData: { records: [], loading: false },
      };
    case 'USERS_LOGOUT':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
