import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { sidebar } from './sidebar.reducer';
import { location } from './location.reducer';
import { organisation } from './organisation.reducer';
import { elements } from './elements.reducer';
import { notifications } from './notifications.reducer';
import { tags } from './tag.reducer';
import { device } from './device.reducer';
import { modal } from './modal.reducer';
import { sideForms } from './sideForms.reducer';
import { navigation } from './navigation.reducer';
import { streams } from './streams.reducer';
import { status } from './status.reducer';
import { userConstants } from '../constants';
import { settings } from './settings.reducer';
import { features } from './features.reducer';
import { user } from './users.reducer';
import { news } from './news.reducer';

const appReducer = combineReducers({
  authentication,
  sidebar,
  location,
  organisation,
  elements,
  notifications,
  tags,
  device,
  modal,
  sideForms,
  navigation,
  streams,
  status,
  settings,
  features,
  user,
  news,
});

export default (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};
