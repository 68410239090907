import { alertConstants, streamsConstants } from '../constants';

const initialState = { items: [] };

export function notifications(state = initialState, action) {
  switch (action.type) {
    case streamsConstants.PASTE_ELEMENTS_SUCCESS: // Add a new item to list
    {
      let alert_item = {
        time: Date.now(),
        message: 'Elemente wurden zu den Streams hinzugefügt',
        title: 'Elemente kopieren',
      };
      return {
        ...state,
        items: [alert_item, ...state.items],
      };
    }
    case alertConstants.SUCCESS: // Add a new item to list
    {
      let alert_item = {
        time: Date.now(),
        message: action.message,
        title: action.title,
      };
      return {
        ...state,
        items: [alert_item, ...state.items],
      };
    }
    case streamsConstants.PASTE_ELEMENTS_FAILURE:
    case alertConstants.ERROR:
    {  
      let alert_item = {
        time: Date.now(),
        message: action.message,
        title: action.title,
      };
      return {
        ...state,
        items: [alert_item, ...state.items],
      };
    }
    
    case alertConstants.CLEAR:
      return initialState;

    default:
      return state;
  }
}
