import defaults from './default.service';

export const statusService = {
  get,
};

function get() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };

  return defaults.defaultFetch({
    url: `/status`,
    requestOptions,
  });
}
