import { takeLeading, put, delay } from 'redux-saga/effects';
import { alertActions } from '../actions';
import { streamsConstants } from '../constants';
import { streamsService } from '../services';

function* pasteElements(action) {
  const { streams, elements } = action.payload;

  if (elements.length <= 0) {
    yield put(alertActions.error('Es wurden keine Elemente ausgewählt'));
    return;
  }

  if (streams.length <= 0) {
    yield put(alertActions.error('Es wurden keine Streams ausgewählt'));
    return;
  }

  yield delay(500);

  try {
    const data = yield streamsService.pasteElements(streams, elements);
    yield put(
      alertActions.success('Die Elemente wurden zu den Streams hinzugefügt')
    );
    yield put({ type: streamsConstants.PASTE_ELEMENTS_SUCCESS, data });
  } catch (error) {
    yield put(alertActions.error(error));
  }
}

export function* streams() {
  yield takeLeading(streamsConstants.PASTE_ELEMENTS_SAGA, pasteElements);
}
