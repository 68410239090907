import { featuresConstants } from '../constants';
import { featuresService } from '../services';

import defaults from './default.actions';

const features_constant = {
  service: featuresService,
  constants: featuresConstants,
};

function get(key) {
  return defaults.get({
    id: key,
    ...features_constant,
  });
}

function getAll() {
  return defaults.all({
    ...features_constant,
  });
}

function update(settings) {
  //let form = prepare({ ...pre_form });
  //let e = validate(form);
  /*if (e) {
        return dispatch => {
        dispatch(alertActions.error(e, 'Stream kann nicht gespeichert werden:'));
        };
    }*/

  console.info('settings:', settings);

  return defaults.update({
    form: { id: settings.ID, settings: settings },
    ...features_constant,
    success_message: 'Einstellungen wurden gespeichert.',
    error_message: 'Einstellung konnten nicht gespeichert werden.',
  });
}

export const featuresActions = {
  get,
  update,
  getAll,
};
