//use axios for file upload, because of the need of progress state for large files (videos)
import axios from 'axios';

import defaults from './default.service';

export const elementsService = {
  ...defaults.services('element', 'elements'),
  create,
  upload,
  getNewestElements,
  get_statistics,
};

function upload(file, uploadProgress) {
  let data = new FormData();
  data.append('file', file, file.name);

  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    data: data,
    timeout: 180000 * 2,
    url: `${process.env.SERVER_URL}/element/upload`,
    onUploadProgress: (p) => {
      uploadProgress(p);
    },
  };

  return axios.request(requestOptions).then((response) => {
    return response;
  });
}

function create(form, file, resolution, rotate = 0) {
  const data = {
    form,
    file,
    resolution,
    rotate,
  };

  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify(data),
  };

  return defaults.defaultFetch({
    url: `/element/create`,
    requestOptions,
  });
}

function getNewestElements() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };
  return defaults.defaultFetch({
    url: `/element/get_newest`,
    requestOptions,
  });
}

function get_statistics() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };
  return defaults.defaultFetch({
    url: `/element/get_statistics`,
    requestOptions,
  });
}
