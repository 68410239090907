import { history } from '../helpers';
import defaults from './default.service';

export const userService = {
  login,
  logout,
  fetchJWTToken,
  pagination: defaults.services('user', 'users').pagination,
  impersonate,
  getCurrentUser,
  changeOwnPassword,
  changePassword,
  get,
  get_license_statistic,
  forgetPassword,
  checkRecoveryToken,
  changePasswordWithToken,
  update,
  create,
  updateWelcomeModal,
  getOrganisations,
  remove: defaults.services('users', 'users').remove,
};

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };
  return fetch(`${process.env.SERVER_URL}/user/login`, requestOptions)
    .then(defaults.handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchJWTToken() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };
  return defaults.defaultFetch({
    url: `/user/refresh`,
    requestOptions,
  });
}

function logout() {
  // remove user from local storage to log user out

  localStorage.removeItem('user');
  history.push('/login');
}

function impersonate(userID) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({ userID }),
  };
  return defaults.defaultFetch({
    url: `/users/impersonate`,
    requestOptions,
  });
}

function getCurrentUser() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };
  return defaults.defaultFetch({
    url: `/profile`,
    requestOptions,
  });
}

function get(id) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };
  return defaults.defaultFetch({
    url: `/users/${id}`,
    requestOptions,
  });
}

function changeOwnPassword(old_password, new_password) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({
      old_password: old_password,
      new_password: new_password,
    }),
  };
  return defaults.defaultFetch({
    url: "/profile/change_password",
    requestOptions,
  });
}

// update the state to show welcome modal
function updateWelcomeModal(id, show) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({
      show,
    }),
  };
  return defaults.defaultFetch({
    url: `/users/${id}/update_welcome_info`,
    requestOptions,
  });
}

function changePassword(
  id,
  send_mail,
  show_password,
  old_password,
  new_password
) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({
      id: id,
      send_mail: send_mail,
      show_password: show_password,
      old_password: old_password,
      new_password: new_password,
    }),
  };
  return defaults.defaultFetch({
    url: `/users/change_password`,
    requestOptions,
  });
}

function get_license_statistic(id) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };

  let url = `/users/${id}/get_license_statistic`;

  return defaults.defaultFetch({
    url: url,
    requestOptions,
  });
}

function forgetPassword(email) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({
      email,
    }),
  };

  return defaults.defaultFetch({
    url: '/users/forget_password',
    requestOptions,
  });
}

function checkRecoveryToken(token) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({
      token,
    }),
  };

  return defaults.defaultFetch({
    url: '/users/check_recovery_token',
    requestOptions,
  });
}

function changePasswordWithToken(token, password) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({
      token,
      password,
    }),
  };
  return defaults.defaultFetch({
    url: `/users/recovery_password_set`,
    requestOptions,
  });
}

function update(form) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify(form),
  };

  if (form['-']) {
    form.model = form['-'];
  }

  let id = -1;

  if (form.model) {
    id = form.model.ID;
  } else {
    id = form.details.ID;
  }

  return defaults.defaultFetch({ url: `/users/${id}`, requestOptions });
}

function create(form) {
  let f = { ...form };

  f.model = {};

  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify(f),
  };

  return defaults.defaultFetch({ url: `/users/new`, requestOptions });
}

function getOrganisations() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };
  return defaults.defaultFetch({
    url: `/users/organisations`,
    requestOptions,
  });
}
