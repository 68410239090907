import defaults from './default.service';

export const locationService = {
  ...defaults.services('location', 'locations'),
  getForSelection,
  getLocationTypes,
  updateLocationTypes,
};

function getForSelection({ filters, search }) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({ filters, search, sortBy: ['created_at ASC'] }),
  };

  return defaults.defaultFetch({
    url: `/selections/locations`,
    requestOptions,
  });
}

function getLocationTypes() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };

  return defaults.defaultFetch({
    url: `/settings/location_types`,
    requestOptions,
  });
}

function updateLocationTypes(location_types) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify(location_types),
  };

  return defaults.defaultFetch({
    url: `/settings/location_types`,
    requestOptions,
  });
}
