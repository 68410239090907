import { userConstants } from '../constants';

import defaults from './default.reducer';

const initialState = {
  loading: false,
  current: {},
  change_password_error: {
    old_password_error: false,
    old_password_error_message: '',
    new_password_error: false,
    new_password_error_message: '',
  },
  valid_recovery_token: false,
  success: false,
  success_message: '',
  orgranisations: [],
};

const user = defaults.reducer(userConstants, initialState, (state, action) => {
  switch (action.type) {
    case userConstants.IMPERSONATE_FAILURE:
      break;

    case userConstants.RESET_ITEM:
      return {
        item: {},
        loading: false,
      };
    case userConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_SUCCESS:
      //let data = action.data.data;
      //data.model = action.data.data['-'];
      return {
        ...state,
        loading: false,
        current: action.data.data,
        item: action.data.data,
      };
    case userConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case userConstants.CHANGE_OWN_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.CHANGE_OWN_PASSWORD_SUCCESS:
      return {
        ...state,
        change_password_error: initialState.change_password_error,
        loading: false,
      };
    case userConstants.CHANGE_OWN_PASSWORD_FAILURE:
      return {
        ...state,
        change_password_error: {
          old_password_error: false,
          old_password_error_message: '',
          new_password_error: false,
          new_password_error_message: '',
        },
        loading: false,
      };

    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        success_message: '',
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        change_password_error: initialState.change_password_error,
        loading: false,
        success: true,
        success_message: 'Das Passwort wurde erfolgreich geändert.',
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        change_password_error: {
          old_password_error: false,
          old_password_error_message: '',
          new_password_error: false,
          new_password_error_message: '',
        },
        loading: false,
        success: false,
        success_message: '',
      };

    case userConstants.GET_LICENSE_STATISTIC_REQUEST: {
      return {
        ...state,
        statistic_licenses_total: 0,
        statistic_licenses_in_use: 0,
      };
    }

    case userConstants.GET_LICENSE_STATISTIC_FAILURE: {
      return {
        ...state,
        statistic_licenses_total: 0,
        statistic_licenses_in_use: 0,
      };
    }

    case userConstants.GET_LICENSE_STATISTIC_SUCCESS: {
      return {
        ...state,
        statistic_licenses_total: action.data.total,
        statistic_licenses_in_use: action.data.in_use,
      };
    }

    case userConstants.CHECK_TOKEN_REQUEST: {
      return {
        ...state,
        valid_recovery_token: false,
        success: false,
        success_message: '',
      };
    }

    case userConstants.CHECK_TOKEN_SUCCESS: {
      return {
        ...state,
        valid_recovery_token: true,
      };
    }

    case userConstants.CHECK_TOKEN_FAILURE: {
      return {
        ...state,
        valid_recovery_token: false,
      };
    }

    case userConstants.GET_ORGANISATIONS_REQUEST: {
      return {
        ...state,
        organisations: [],
      };
    }

    case userConstants.GET_ORGANISATIONS_SUCCESS: {
      return {
        ...state,
        organisations: action.data.data,
      };
    }

    case userConstants.GET_ORGANISATIONS_FAILURE: {
      return {
        ...state,
        organisations: [],
      };
    }

    default:
      return state;
  }
});

export { user };
