import { authHeader } from '../helpers';
import { userService } from './user.service';

export const tagsService = {
  get,
};

function get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.SERVER_URL}/tags`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.status === false) {
        const error = data.message;
        return Promise.reject(error);
      }
    }

    return data;
  });
}
