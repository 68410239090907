import { statusConstants } from '../constants';

const initialState = {
  device_errors: [],
  stream_errors: [],
};

export function status(state = initialState, action) {
  switch (action.type) {
    case statusConstants.GET_SUCCESS:
      return {
        ...state,
        device_errors: action.data.data.device_errors
          ? action.data.data.device_errors
          : [],
        stream_errors: action.data.data.stream_errors
          ? action.data.data.stream_errors
          : [],
      };
    default:
      return state;
  }
}
