import defaults from './default.service';

export const settingsService = {
  get,
  update,
};

function get(id) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };

  return defaults.defaultFetch({
    url: `/settings/${id}`,
    requestOptions,
  });
}

function update(form) {
  const { settings, key } = form;

  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify(settings),
  };

  return defaults.defaultFetch({ url: `/settings/${key}`, requestOptions });
}
