import defaults from './default.service';

export const streamsService = {
  ...defaults.services('stream', 'streams'),
  pasteElements,
};

function pasteElements(streams, elements) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({ elements, streams }),
  };

  return defaults.defaultFetch({
    url: `/streams/paste_elements`,
    requestOptions,
  });
}
