import React from 'react';
import { alertConstants } from '../constants';
import { toast } from 'react-toastify';
import { ToastNotification } from '@carbon/react';

export const alertActions = {
  success,
  error,
  clear,
};

function success(message, title = '') {
  toast(
    <CustomToastNotification
      kind="info"
      title={title}
      message={`${message}`}
    />,
    {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    }
  );
  return { type: alertConstants.SUCCESS, message };
}

const CustomToastNotification = ({
  message,
  title,
  closeToast,
  kind = 'info',
}) => (
  <ToastNotification
    kind={kind}
    caption={message}
    title={title}
    onCloseButtonClick={closeToast}
  />
);

function error(message, title = '') {
  toast(
    <CustomToastNotification
      kind="error"
      title={title}
      message={`${
        message.message
          ? message.message
          : message.error
            ? message.error
            : message
      }`}
    />,
    {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    }
  );
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}
