export const modalActions = {
  confirmRemoveElement,
  confirmRemoveStream,
  confirmRemoveDevice,
  confirmRemoveLocation,
  hideModal,
  confirmClosingUnsavedForm,
  confirmNewStreamLostUnsaved,
  confirmPasswordReset,
  confirmRemoveMultipleElements,
  confirmRemoveUser,
  showMirrorWizzard,
};

function confirmRemoveUser(next, user) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'DELETE_USER',
      modalProps: {
        next,
        user,
      },
    });
  };
}

function confirmRemoveElement(element) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'DELETE_ELEMENT',
      modalProps: {
        element,
      },
    });
  };
}

function confirmRemoveMultipleElements(next) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'DELETE_MULTIPLE_ELEMENTS',
      modalProps: { next },
    });
  };
}

function confirmRemoveDevice(device) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'DELETE_DEVICE',
      modalProps: {
        device,
      },
    });
  };
}

function confirmRemoveLocation(location) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'DELETE_LOCATION',
      modalProps: {
        location,
      },
    });
  };
}

function confirmRemoveStream(stream) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'DELETE_STREAM',
      modalProps: {
        stream,
      },
    });
  };
}

function confirmClosingUnsavedForm(next) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'CLOSE_UNSAVED_FORM_MODAL',
      modalProps: { next },
    });
  };
}

function confirmNewStreamLostUnsaved({ onOkay }) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'NEW_STREAM_LOST_UNSAVED_MODAL',
      modalProps: { onOkay },
    });
  };
}

function confirmPasswordReset(email) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'CONFIRM_PASSWORD_RESET',
      modalProps: { email },
    });
  };
}

function hideModal() {
  return (dispatch) => {
    dispatch({
      type: 'HIDE_MODAL',
    });
  };
}

function showMirrorWizzard(device) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'CREATE_MIRROR_DEVICE',
      modalProps: device,
    });
  };
}
