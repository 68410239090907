import { navigationConstants } from '../constants';

//let navigation = JSON.parse(localStorage.getItem('navigation'));
const initialState = {
  userMenuVisible: false,
  leftSideNavVisible: false,
  adminState: '',
  profileState: '',
};

export function navigation(state = initialState, action) {
  switch (action.type) {
    case navigationConstants.TOGGLE_USER_MENU:
      return { ...state, userMenuVisible: !state.userMenuVisible };
    case navigationConstants.TOGGLE_LEFT_SIDE_NAV_MENU:
      return { ...state, leftSideNavVisible: !state.leftSideNavVisible };
    case navigationConstants.HIDE_USER_MENU:
      return { ...state, userMenuVisible: false };
    case navigationConstants.SHOW_LEFT_SIDE_NAV_MENU:
      return { ...state, leftSideNavVisible: true };
    case navigationConstants.HIDE_LEFT_SIDE_NAV_MENU:
      return { ...state, leftSideNavVisible: false };
    case navigationConstants.HIDE_ALL_MENUS:
      return { ...state, leftSideNavVisible: false, userMenuVisible: false };
    case navigationConstants.ADMIN_GOTO:
      return { ...state, adminState: action.target };
    case navigationConstants.PROFILE_GOTO:
      return { ...state, profileState: action.target };
    case 'USERS_LOGOUT':
      return {
        ...initialState,
      };
    case 'USERS_LOGIN_SUCCESS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
