import defaults from './default.service';

export const deviceService = {
  ...defaults.services('device', 'devices'),
  preAssign,
  checkKey,
  assignToLocation,
  getForLocation,
  get_statistics,
  refresh_mem,
  assignMirrorToDevice,
};

function preAssign() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };

  return defaults.defaultFetch({
    url: '/device/pre_assign',
    requestOptions,
  });
}

function checkKey(key) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({ key }),
  };

  return defaults.defaultFetch({
    url: '/device/check',
    requestOptions,
  });
}

function refresh_mem(id, mirror_server_id = 0) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };

  if (mirror_server_id > 0) {
    return defaults.defaultFetch({
      url: `/device/${id}/mem`,
      requestOptions,
    });
  } else {
    return defaults.defaultFetch({
      url: `/device/${id}/mem`,
      requestOptions,
    });
  }
}

function assignToLocation(location_id, key, label, orientation) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({ location_id, key, label, orientation }),
  };

  return defaults.defaultFetch({
    url: '/device/assign',
    requestOptions,
  });
}

function assignMirrorToDevice(
  mirror_server_device_id,
  key,
  label,
  orientation
) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('POST'),
    body: JSON.stringify({ mirror_server_device_id, key, label, orientation }),
  };

  return defaults.defaultFetch({
    url: '/device/mirror_assign',
    requestOptions,
  });
}

function getForLocation(location_id) {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };

  return defaults.defaultFetch({
    url: `/location/${location_id}/devices`,
    requestOptions,
  });
}

function get_statistics() {
  const requestOptions = {
    ...defaults.defaultRequestOptions('GET'),
  };
  return defaults.defaultFetch({
    url: `/device/get_statistics`,
    requestOptions,
  });
}
