import { all } from 'redux-saga/effects';

import { elements } from './elements';
import { streams } from './streams';
import { users } from './users';

function* rootSaga() {
  yield all([elements(), streams(), users()]);
}

export default rootSaga;
