const rsf = (action, prefix) => {
  const uaction = action.toUpperCase();
  const uprefix = prefix.toUpperCase();

  return {
    [`${uaction}_REQUEST`]: `${uprefix}_${uaction}_REQUEST`,
    [`${uaction}_SUCCESS`]: `${uprefix}_${uaction}_SUCCESS`,
    [`${uaction}_FAILURE`]: `${uprefix}_${uaction}_FAILURE`,
  };
};

const change = (action, prefix) => {
  const uaction = action.toUpperCase();
  const uprefix = prefix.toUpperCase();

  return {
    [`${uaction}_CHANGE`]: `${uprefix}_${uaction}_CHANGE`,
  };
};

const list_size = (prefix) => {
  return change('list_size', prefix);
};

const constants = (prefix = 'UNKNOWN') => {
  return {
    ...rsf('get', prefix),
    ...rsf('all', prefix),
    ...rsf('pagination', prefix),
    ...rsf('create', prefix),
    ...rsf('update', prefix),
    ...rsf('remove', prefix),
    [`NEW_REQUEST`]: `${prefix.toUpperCase()}_NEW_REQUEST`,
  };
};

const defaults = {
  rsf,
  change,
  list_size,
  constants,
};

export default defaults;
