import { sidebarConstants } from '../constants';
import { navigationConstants } from '../constants';
import { navigationService } from '../services';

export const sidebarActions = {
  getNavigation,
  toggleSidebar,
  toggleDropdown,
  toggleUserMenu,
  toggleLeftSideNav,
  hideUserMenu,
  hideLeftSideNav,
  showLeftSideNav,
  hideAllMenus,
};

function getNavigation() {
  return dispatch => {
    dispatch(request());

    navigationService.getNavigation().then(
      data => dispatch(success(data.navigation)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: sidebarConstants.GETNAVIGATION_REQUEST };
  }
  function success(navigation) {
    return { type: sidebarConstants.GETNAVIGATION_SUCCESS, navigation };
  }
  function failure(error) {
    return { type: sidebarConstants.GETNAVIGATION_FAILURE, error };
  }
}

function toggleSidebar() {
  return dispatch => {
    dispatch({ type: sidebarConstants.TOGGLE_SIDEBAR });
  };
}

function toggleDropdown(item) {
  return dispatch => {
    dispatch({ type: sidebarConstants.TOGGLE_SIDEBAR_DROPDOWN, item: item });
  };
}

function toggleUserMenu() {
  return dispatch => {
    dispatch({ type: navigationConstants.TOGGLE_USER_MENU });
  };
}

function toggleLeftSideNav() {
  return dispatch => {
    dispatch({ type: navigationConstants.TOGGLE_LEFT_SIDE_NAV_MENU });
  };
}

function hideLeftSideNav() {
  return dispatch => {
    dispatch({ type: navigationConstants.HIDE_LEFT_SIDE_NAV_MENU });
  };
}

function showLeftSideNav() {
  return dispatch => {
    dispatch({ type: navigationConstants.SHOW_LEFT_SIDE_NAV_MENU });
  };
}

function hideUserMenu() {
  return dispatch => {
    dispatch({ type: navigationConstants.HIDE_USER_MENU });
  };
}

function hideAllMenus() {
  return dispatch => {
    dispatch({ type: navigationConstants.HIDE_ALL_MENUS });
  };
}
