import { authHeader } from '../helpers';
import { userService } from './user.service';

export const navigationService = {
  getNavigation,
};

function getNavigation() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.SERVER_URL}/navigation/items`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('navigation', JSON.stringify(data.navigation));

      return data;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.status === false) {
        const error = data.message;
        return Promise.reject(error);
      }
    }

    return data;
  });
}
