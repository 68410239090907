import defaults from './default.constants';

export const userConstants = {
  ...defaults.constants('users'),

  ...defaults.rsf('get_license_statistic', 'users'),
  ...defaults.rsf('change_password', 'users'),
  ...defaults.rsf('change_own_password', 'users'),

  ...defaults.rsf('forget_password', 'users'),

  ...defaults.rsf('check_token', 'users'),

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GET_REQUEST: 'USER_GET_REQUEST',
  GET_SUCCESS: 'USER_GET_SUCCESS',
  GET_FAILURE: 'USER_GET_FAILURE',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  IMPERSONATE_REQUEST: 'USERS_IMPERSONATE_REQUEST',
  IMPERSONATE_FAILURE: 'USERS_IMPERSONATE_FAILURE',

  OPEN_FORM_SAGA: 'USERS_OPEN_FORM_SAGA',
  RESET_ITEM: 'USERS_RESET_ITEM',
  UPDATE_WELCOME_SUCCESS: 'USERS_UPDATE_WELCOME_SUCCESS',

  GET_ORGANISATIONS_REQUEST: 'USERS_GET_ORGANISATIONS_REQUEST',
  GET_ORGANISATIONS_SUCCESS: 'USERS_GET_ORGANISATIONS_SUCCESS',
  GET_ORGANISATIONS_FAILURE: 'USERS_GET_ORGANISATIONS_FAILURE',
};
