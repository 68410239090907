import defaults from './default.constants';

export const deviceConstants = {
  ...defaults.constants('devices'),
  ...defaults.rsf('check_key', 'devices'),
  ...defaults.rsf('assign_to_location', 'devices'),
  ...defaults.rsf('assign_mirror_to_device', 'devices'),

  CLEAR_STATE: 'DEVICES_CLEAR_STATE',

  GET_DEVICES_FOR_LOCATION_REQUEST: 'GET_DEVICES_FOR_LOCATION_REQUEST',
  GET_DEVICES_FOR_LOCATION_SUCCESS: 'GET_DEVICES_FOR_LOCATION_SUCCESS',
  GET_DEVICES_FOR_LOCATION_FAILURE: 'GET_DEVICES_FOR_LOCATION_FAILURE',

  ...defaults.rsf('get_statistics', 'devices'),
  ...defaults.rsf('refresh_mem', 'devices'),
};
