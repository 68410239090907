import { authHeader } from '../helpers';
import { userService } from './user.service';

import timeoutFetch from './fetchTimeout';

const defaultFetch = ({
  url,
  requestOptions = defaultRequestOptions,
  responseHandler = defaults.handleResponse,
  timeout = 80000,
}) => {
  return timeoutFetch(timeout, fetch(`${process.env.SERVER_URL}${url}`, requestOptions))
    .then(responseHandler);
};

const defaultRequestOptions = (method = 'GET') => {
  return {
    method,
    headers: authHeader(),
  };
};

const services = (prefix, prefixs) => {
  const get = (id) => {
    const requestOptions = {
      ...defaultRequestOptions('GET'),
    };
    return defaultFetch({ url: `/${prefix}/${id}`, requestOptions });
  };

  const update = (form) => {
    const requestOptions = {
      ...defaultRequestOptions('POST'),
      body: JSON.stringify(form),
    };

    let id = -1;

    if (form.model) {
      id = form.model.ID;
    } else {
      if (form.item) {
        if (form.item.model) {
          id = form.item.model.ID;
        } else {
          id = form.item.details.ID;
        }
      } else {
        id = form.details.ID;
      }
    }

    return defaultFetch({ url: `/${prefix}/${id}`, requestOptions });
  };

  const create = (form) => {
    const requestOptions = {
      ...defaultRequestOptions('POST'),
      body: JSON.stringify(form),
    };

    return defaultFetch({ url: `/${prefix}`, requestOptions });
  };

  const remove = (id) => {
    const requestOptions = {
      ...defaultRequestOptions('DELETE'),
    };
    return defaultFetch({ url: `/${prefix}/${id}`, requestOptions });
  };

  const all = (skip = 0, limit = 5, filter = {}) => {
    const form = {
      skip,
      limit,
      filter,
    };

    const requestOptions = {
      ...defaultRequestOptions('POST'),
      body: JSON.stringify(form),
    };
    return defaultFetch({ url: `/${prefixs}`, requestOptions });
  };

  const pagination = (
    pageIndex = 0,
    pageSize = 5,
    sortBy = [],
    filters = [],
    search = null
  ) => {
    const form = {
      pageIndex,
      pageSize,
      sortBy,
      filters,
      search,
    };

    const requestOptions = {
      ...defaultRequestOptions('POST'),
      body: JSON.stringify(form),
    };
    return defaultFetch({ url: `/${prefixs}`, requestOptions });
  };

  return {
    get,
    all,
    pagination,
    update,
    remove,
    create,
  };
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    if (response.status === 404 || response.status === 500) {
      return Promise.reject({
        status: `Fehler ${response.status}`,
        error: text
          ? text
          : 'Vorgang musste abgebrochen werden!\nProbiere es nochmal oder kontaktiere den Support.',
      });
    }

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        //console.info("now i push to login cause i am not logged in");
        //history.push('/login');
      }

      try {
        const error_text = text && JSON.parse(text);
        return Promise.reject({ error: error_text, status: response.status });
      } catch (err) {
        return Promise.reject({ error: text, status: response.status });
      }
    } else {
      try {
        const data = text && JSON.parse(text);
        if (data.status === false) {
          const error = data.message;
          return Promise.reject({ error: error, status: response.status });
        }
      } catch (err) {
        return Promise.reject({ error: text, status: response.status });
      }
    }

    // if no error then go to normal processing
    const data = text && JSON.parse(text);
    return data;
  });
};

const defaults = {
  defaultRequestOptions,
  services,
  handleResponse,
  defaultFetch,
};

export default defaults;
