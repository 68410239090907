import { locationConstants } from '../constants';
import { locationService } from '../services';
import { history } from '../helpers';

import defaults from './default.actions';
import { alertActions } from './alert.actions';

import { modalActions } from './modal.actions';

const service_constant = {
  service: locationService,
  constants: locationConstants,
};

export const locationActions = {
  getAll,
  get,
  update,
  selectLocation,
  getForSelection,
  showForm,
  createNew,
  create,
  getLocationTypes,
  updateLocationTypes,
  remove,
};

function validate(form) {
  let errors = [];

  if (form.name.length < 2) {
    errors.push('Es muss ein Name angegeben werden');
  }

  return errors.length < 1 ? null : errors.join('<br/>');
}

function update(form) {
  let e = validate(form);
  if (e) {
    return (dispatch) => {
      dispatch(
        alertActions.error(e, 'Der Standort kann nicht gespeichert werden:')
      );
    };
  }
  return defaults.update({
    form,
    ...service_constant,
    success_message: 'Standort einstellungen wurden gespeichert.',
    error_message: 'Standort einstellungen konnten nicht gespeichert werden.',
  });
}

function selectLocation(id) {
  return (dispatch) => {
    dispatch({ type: locationConstants.SELECT_LOCATION, id });
    history.push('/location/details');
  };
}

function getForSelection(search = '', after_hook = () => {}) {
  return (dispatch) => {
    dispatch(request());
    locationService.getForSelection({ filters: [], search: search }).then(
      (data) => {
        after_hook(data.data);
        dispatch(success(data.data));
      },
      (error) => dispatch(failure(error.error))
    );
  };

  function request() {
    return { type: locationConstants.GETFORSELECTION_REQUEST };
  }
  function success(locations) {
    return { type: locationConstants.GETFORSELECTION_SUCCESS, locations };
  }
  function failure(error) {
    return { type: locationConstants.GETFORSELECTION_FAILURE, error };
  }
}

function get(id) {
  return defaults.get({
    id,
    ...service_constant,
  });
}

function showForm(id) {
  return defaults.show_side_form({
    id,
    name: 'LOCATION_FORM',
  });
}

function getAll(options) {
  return defaults.pagination({
    ...service_constant,
    ...options,
  });
}

function createNew() {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'LOCATION_WIZARD',
      modalProps: {},
    });
  };
}

function create(form) {
  // fix some errors... new eleemts in items. remove id if begins with new_
  let e = validate(form);
  if (e) {
    return (dispatch) => {
      dispatch(alertActions.error(e, 'Standort kann nicht angelegt werden:'));
    };
  }

  return defaults.create({
    form,
    success_message: 'Standort wurde erfolgreich angelegt!',
    success_callback: (_d, dispatch) => {
      dispatch(modalActions.hideModal());
    },
    ...service_constant,
  });
}

function getLocationTypes() {
  return (dispatch) => {
    dispatch(request());
    locationService.getLocationTypes().then(
      (data) => dispatch(success(data.data)),
      (error) => dispatch(failure(error.error))
    );
  };

  function request() {
    return { type: locationConstants.GETLOCATIONTYPES_REQUEST };
  }
  function success(types) {
    return { type: locationConstants.GETLOCATIONTYPES_SUCCESS, types };
  }
  function failure(error) {
    return { type: locationConstants.GETLOCATIONTYPES_FAILURE, error };
  }
}

function updateLocationTypes(items) {
  return (dispatch) => {
    dispatch(request());

    let setItems = items.map((item) => {
      if (item.isNew) {
        return { name: item.name };
      }

      return { name: item.name, model: { ID: item.model.ID } };
    });
    // fixing the update
    console.info(setItems);

    locationService.updateLocationTypes(setItems).then(
      (data) => {
        dispatch(
          alertActions.success('Die Änderungen wurden erfolgreich gespeichert')
        );
        dispatch(success(data.data));
      },
      (error) => {
        dispatch(
          alertActions.error(
            'Die neuen Gewerbearten konnten nicht gespeichert werden'
          )
        );
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: locationConstants.UPDATELOCATIONTYPES_REQUEST };
  }
  function success(types) {
    return { type: locationConstants.UPDATELOCATIONTYPES_SUCCESS, types };
  }
  function failure(error) {
    return { type: locationConstants.UPDATELOCATIONTYPES_FAILURE, error };
  }
}

function remove(id) {
  return defaults.remove({
    id,
    ...service_constant,
    success_message: 'Standort wurde gelöscht.',
    error_message: 'Standort konnte nicht gelöscht werden.',
  });
}
