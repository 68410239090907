import { createStore, applyMiddleware, compose } from 'redux';

import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import { jwt } from './helpers/auth-middleware';
import rootSaga from './saga';

const loggerMiddleware = createLogger();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize..
      trace: true,
      traceLimit: 25,
    })
    : compose;

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeEnhancers(
  applyMiddleware(jwt, sagaMiddleware, thunkMiddleware, loggerMiddleware),
);
const store = createStore(rootReducer, enhancer);

function setAuthState(state) {
  try {
    localStorage.setItem(
      'state.authentication.token',
      JSON.stringify((state.authentication || {}).token),
    );
    localStorage.setItem(
      'state.authentication.user',
      JSON.stringify((state.authentication || {}).user),
    );
  } catch (err) {
    return undefined;
  }
}

store.subscribe(() => {
  setAuthState(store.getState());
});

sagaMiddleware.run(rootSaga);

export { store };
