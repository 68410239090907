import {
  //takeLeading,
  takeLatest,
  put,
  delay,
  select,
  all,
} from 'redux-saga/effects';
import { elementsConstants } from '../constants';
import { elementsService } from '../services';

export const getElements = (state) => state.elements;

function* fetchElement(action) {
  let element = yield select(getElements);
  const { filter, limit, page, skip } = action.payload;

  if (
    element.total > 0 &&
    element.total === element.items.length &&
    skip !== -1
  ) {
    //yield put({type: elementsConstants.ALL_FAILURE, error: "EMPTY NOTHING MORE! STOP!"});
    return;
  }

  yield put({
    type: elementsConstants.ALL_REQUEST,
    reset: skip === -1,
    limit,
    page,
  });

  yield delay(200);

  try {
    const data = yield elementsService.all(
      skip === -1 ? 0 : skip,
      limit,
      filter
    );
    yield put({ type: elementsConstants.ALL_SUCCESS, data });
  } catch (error) {
    yield put({ type: elementsConstants.ALL_FAILURE, error });
  }
}

function* getStatistics(_action) {
  yield delay(500);

  try {
    const data = yield elementsService.get_statistics();
    yield put({ type: elementsConstants.GET_STATISTICS_SUCCESS, data });
  } catch (error) {
    yield put({ type: elementsConstants.GET_STATISTICS_FAILURE, error });
  }
}

export function* elements() {
  yield all([
    yield takeLatest(elementsConstants.ALL_REQUEST_SAGA, fetchElement),
    yield takeLatest(elementsConstants.GET_STATISTICS_SAGA, getStatistics),
  ]);
}
