import defaults from './default.constants';

export const locationConstants = {
  ...defaults.constants('locations'),

  SELECT_LOCATION: 'LOCATIONS_SELECT_LOCATION',
  GETFORSELECTION_REQUEST: 'LOCATIONS_GETFORSELECTION_REQUEST',
  GETFORSELECTION_SUCCESS: 'LOCATIONS_GETFORSELECTION_SUCCESS',
  GETFORSELECTION_FAILURE: 'LOCATIONS_GETFORSELECTION_FAILURE',

  GETLOCATIONTYPES_REQUEST: 'GETLOCATIONTYPES_REQUEST',
  GETLOCATIONTYPES_SUCCESS: 'GETLOCATIONTYPES_SUCCESS',
  GETLOCATIONTYPES_FAILURE: 'GETLOCATIONTYPES_FAILURE',

  UPDATELOCATIONTYPES_REQUEST: 'UPDATELOCATIONTYPES_REQUEST',
  UPDATELOCATIONTYPES_SUCCESS: 'UPDATELOCATIONTYPES_SUCCESS',
  UPDATELOCATIONTYPES_FAILURE: 'UPDATELOCATIONTYPES_FAILURE',
};
