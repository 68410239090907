import { streamsConstants } from '../constants';

import defaults from './default.reducer';

const initialState = {};

const streams = defaults.reducer(
  streamsConstants,
  initialState,
  (state, action) => {
    switch (action.type) {
      case streamsConstants.ALL_SUCCESS:
        state.items.forEach((stream) => {
          (stream.items || []).forEach((stream_item) => {
            if (stream_item.element_type === "stream") {
              stream_item.elment = stream_item.stream_element;
            }
          })
        });

        return {
          ...state,
        };
      case 'USERS_LOGOUT':
        return {
          ...initialState,
        };
      default:
        return state;
    }
  }
);

export { streams };
