const default_reducer = (constants, initialState, states) => {
  const defaultState = {
    //default loading state
    loading: false,

    //single item receive
    item: null,

    //default pagination:
    items: [],
    skip: 0,
    limit: 10,
    hasMore: true,

    first_row_index: 0,
    current_page: 1,

    //saving / updating
    saved: true,
    is_saving: false,

    error: false,
    error_message: '',
    error_code: 0,
    error_type: '',

    page: 1,
    pages: 0,
    total: 0,
    pageSize: 10,
    sortBy: [],

    listItemsSize: 'normal',

    ...initialState,
  };

  const reducer = (state = defaultState, action) => {
    const firstStage = () => {
      switch (action.type) {
        case constants.GET_REQUEST:
          return {
            ...state,
            item: null,
            loading: true,
          };
        case constants.GET_SUCCESS:
          return {
            ...state,
            item: action.data.data,
            loading: false,
          };
        case constants.GET_FAILURE:
          return {
            ...state,
            item: null,
            loading: false,
            error: true,
            error_message: action.message,
            // later add error message here
          };
        case constants.REMOVE_REQUEST:
          return {
            ...state,
            loading: true,
            error: false,
            error_message: '',
          };
        case constants.REMOVE_FAILURE:
          return {
            ...state,
            loading: false,
            error: true,
            error_message: action.message,
          };
        case constants.REMOVE_SUCCESS:
          return {
            ...state,
            items: state.items.filter((i) => i.model.ID !== action.id),
            item: state.item
              ? state.item.model
                ? state.item.model.ID === action.id
                  ? {}
                  : state.item
                : state.item
              : state.item,
            loading: false,
          };
        case constants.ALL_REQUEST:
          return {
            ...state,
            limit: action.limit || state.limit,
            items: action.reset === true ? [] : state.items,
            skip: action.reset === true ? 0 : state.skip,
            current_page: action.page || state.current_page,
            first_row_index:
              (action.limit || state.limit) *
              ((action.page || state.current_page) - 1),
            loading: true,
            error: false,
            error_message: '',
          };
        case constants.ALL_SUCCESS: {
          if (action.data.data.records && action.data.data.prev_page) {
            return {
              ...state,
              items: action.data.data.records,
              page: action.data.data.page,
              pages: action.data.data.total_page,
              total: action.data.data.total_record,
              pageSize: action.data.data.limit,
              sortBy: action.data.data.order_by,
              loading: false,
              error: false,
              error_message: '',
            };
          } else {
            let items = state.items;
            if (action.data.count > (state.items || []).length) {
              items = [...(state.items || []), ...action.data.data];
            }

            return {
              ...state,
              item: null, // nach einem refresh item zurücksetzen
              items: items,
              skip: state.skip + action.data.data.length,
              hasMore: action.data.count > items.length,
              loading: false,
              total: action.data.count,
              error: false,
              error_message: '',
            };
          }
        }
        case constants.ALL_FAILURE:
          return {
            ...state,
            loading: false,
            error: true,
            error_message: action.message,
          };
        case constants.PAGINATION_REQUEST:
          return {
            ...state,
            limit: action.limit || state.limit,
            items: action.reset === true ? [] : state.items,
            skip: action.reset === true ? 0 : state.skip,
            current_page: action.page || state.current_page,
            first_row_index:
              (action.limit || state.limit) *
              ((action.page || state.current_page) - 1),
            loading: true,
            error: false,
            error_message: '',
          };
        case constants.PAGINATION_SUCCESS: {
          if (action.data.data.records && action.data.data.prev_page) {
            return {
              ...state,
              items: action.data.data.records,
              page: action.data.data.page,
              pages: action.data.data.total_page,
              total: action.data.data.total_record,
              pageSize: action.data.data.limit,
              sortBy: action.data.data.order_by,
              loading: false,
              error: false,
              error_message: '',
            };
          } else {
            return {
              ...state,
              item: null, // nach einem refresh item zurücksetzen
              items: [...state.items, ...action.data.data],
              skip: state.skip + action.data.data.length,
              hasMore: action.data.count > state.skip + action.data.data.length,
              loading: false,
              total: action.data.count,
              error: false,
              error_message: '',
            };
          }
        }
        case constants.PAGINATION_FAILURE:
          return {
            ...state,
            loading: false,
            error: true,
            error_message: action.message,
          };
        case constants.NEW_REQUEST:
          return {
            ...state,
            item: null,
            loading: false,
            is_saving: false,
            saved: false,
            error: false,
            error_message: '',
          };
        case constants.UPDATE_REQUEST:
          return {
            ...state,
            is_saving: true,
            saved: false,
            error: false,
            error_message: '',
            error_code: defaultState.error_code,
            error_type: defaultState.error_type,
          };
        case constants.UPDATE_SUCCESS: {
          let items = state.items;

          items.forEach((item, index) => {
            if (item.model.ID === action.data.data.model.ID) {
              items[index] = action.data.data;
            }
          });

          return {
            ...state,
            item: action.data.data,
            items,
            is_saving: false,
            saved: true,
            error: false,
            error_message: '',
            error_code: defaultState.error_code,
            error_type: defaultState.error_type,
          };
        }
        case constants.UPDATE_FAILURE:
          return {
            ...state,
            is_saving: false,
            saved: false,
            error: true,
            error_message: action.message || action.error,
            error_code: state.error_code || action.error_code,
            error_type: state.error_type || action.error_type,
          };
        case constants.CREATE_REQUEST:
          return {
            ...state,
            is_saving: true,
            saved: false,
            error: false,
            error_message: '',
            error_code: defaultState.error_code,
            error_type: defaultState.error_type,
          };
        case constants.CREATE_FAILURE:
          return {
            ...state,
            is_saving: false,
            error: true,
            error_message: action.message || action.error,
            error_code: state.error_code || action.error_code,
            error_type: state.error_type || action.error_type,
          };
        case constants.CREATE_SUCCESS:
          return {
            ...state,
            is_saving: false,
            saved: true,
            error: false,
            error_message: '',
            error_code: defaultState.error_code,
            error_type: defaultState.error_type,
            item: action.data.data,
            total: state.total + 1,
            items: [...state.items, ...[action.data.data]],
          };
        case 'USERS_LOGOUT':
          return { ...defaultState };
        default:
          if (
            constants.LIST_SIZE_CHANGE &&
            action.type === constants.LIST_SIZE_CHANGE
          ) {
            if (
              action.size === 'normal' ||
              action.size === 'small' ||
              action.size === 'large'
            ) {
              return {
                ...state,
                listItemsSize: action.size,
              };
            } else {
              return {
                ...state,
              };
            }
          } else {
            return state;
          }
      }
    };

    let fristStageState = firstStage();
    // call it additional
    let secondStage = states(fristStageState, action);
    if (secondStage) {
      return secondStage;
    } else {
      return fristStageState;
    }
  };

  return reducer;
};

const defaults = {
  reducer: default_reducer,
};

export default defaults;
