import { elementsConstants } from '../constants';
import defaults from './default.reducer';

const initialState = {
  tmp_image: '',
  file: null,
  is_new: false,
  is_uploading: false,
  tags: [],
  counts: {},
  current_upload_progress: 0,

  item_in_streams: [],
  item_in_streams_count: 0,
};

const elements = defaults.reducer(
  elementsConstants,
  initialState,
  (state, action) => {
    switch (action.type) {
      case elementsConstants.ALL_SUCCESS:
        return {
          ...state,
          tags: action.data.tags,
          counts: action.data.counts,
        };
      case elementsConstants.UPLOAD_REQUEST:
        return {
          ...state,
          is_uploading: true,
          current_upload_progress: 0,
        };
      case elementsConstants.UPLOAD_PROGRESS:
        return {
          ...state,
          current_upload_progress: action.progress,
          is_uploading: true,
        };
      case elementsConstants.UPLOAD_SUCCESS:
        return {
          ...state,
          current_upload_progress: 100,
          is_uploading: false,
        };
      case elementsConstants.CREATE_SUCCESS: {
        let items = state.items;
        items = [action.data.data,...(state.items || [])];
        
        return {
          ...state,
          items: items,
          current_upload_progress: 0,
          tmp_image: '',
          tags: action.data.tags,
          statistic_count: null,
        };
      }
      case elementsConstants.CREATE_FAILURE:
        return {
          ...state,
          is_uploading: false,
          current_upload_progress: 0,
        };
      case elementsConstants.GET_REQUEST: {
        return {
          ...state,
          item_in_streams: [],
          item_in_streams_count: 0,
        };
      }
      case elementsConstants.DELETE_SUCCESS: {
        return {
          ...state,
          statistic_count: null,
        };
      }
      case elementsConstants.GET_SUCCESS: {
        return {
          ...state,
          item_in_streams: action.data.streams,
          item_in_streams_count: action.data.stream_counts,
        };
      }
      case elementsConstants.GET_STATISTICS_SUCCESS: {
        return {
          ...state,
          statistic_count: action.data.count,
          statistic_usage: action.data.total,
        };
      }
      case 'USERS_LOGOUT':
        return {
          ...initialState,
        };
      default:
        return state;
    }
  }
);

export { elements };
