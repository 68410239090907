import { userConstants } from '../constants';

export const get_from_store = (key) => {
  let d = localStorage.getItem(key);
  if (d && d !== 'undefined') {
    return JSON.parse(d);
  } else {
    return null;
  }
};

let token_from_store = get_from_store('state.authentication.token');
let user_from_store = get_from_store('state.authentication.user');

const defaultState = {
  loggedIn: false,
  user: {},
  token: '',
  error: false,
  error_message: '',
  isAdmin: false,
  isImposter: false,
  isOwner: false,
};

const initialState =
  token_from_store && user_from_store
    ? {
      ...defaultState,
      user: user_from_store,
      token: token_from_store,
      isAdmin:
        user_from_store &&
        user_from_store.role &&
        user_from_store.role.name === 'Administrator',
      isOwner:
        user_from_store &&
        user_from_store.role &&
        user_from_store.role.name === 'User',
      loggedIn: true,
    }
    : defaultState;

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: {},
        token: '',
        error: false,
        error_message: '',
        isAdmin: false,
        isOwner: false,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.data.user,
        token: action.data.token,
        isAdmin:
          action.data.user &&
          action.data.user.role &&
          action.data.user.role.name === 'Administrator',
        isOwner:
          user_from_store &&
          user_from_store.role &&
          user_from_store.role.name === 'User',
        isImposter: action.data.imposter,
        error: false,
        error_message: '',
      };

    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        user: {},
        token: '',
        error_message: action.error,
        error: true,
        isAdmin: false,
      };
    case userConstants.LOGOUT:
      localStorage.clear();
      return {
        ...defaultState,
      };

    case 'ERROR_REFRESHING_TOKEN': {
      localStorage.clear();
      return {
        ...defaultState,
      };
    }
    case 'DONE_REFRESHING_TOKEN': {
      if (action.token) {
        return {
          ...state,
          token: action.token,
        };
      } else {
        return {
          ...state,
          loggedIn: false,
          user: {},
          token: '',
          error: true,
          isAdmin: false,
          error_message: action.error || '',
        };
      }
    }

    case userConstants.UPDATE_WELCOME_SUCCESS: {
      return {
        ...state,
        user: { ...state.user, welcome_info: action.show },
      };
    }

    default:
      return state;
  }
}
