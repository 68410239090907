import { elementsConstants } from '../constants';
import { elementsService } from '../services';
import { alertActions } from '.';
import { tagsActions } from '.';

import defaults from './default.actions';
import { sideFormsActions } from './sideForms.actions';

const service_constant = {
  service: elementsService,
  constants: elementsConstants,
};

export const elementsActions = {
  get,
  getAll,
  newForm,
  newMultiUploadForm,
  resetElementForm,
  createElement,
  update,
  remove,
  showForm,
  showResolutionWarning,
  getNewestElements,
  set_list_size,
  get_statistics,
};

function showResolutionWarning(title = '', content = '') {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'WRONG_RESOLUTION',
      modalProps: { title, content },
    });
  };
}

function get(id) {
  return defaults.get({
    id,
    ...service_constant,
    before_hook: () => {
      tagsActions.get();
    },
  });
}

function set_list_size(size = 'normal') {
  return defaults.set_list_size({ ...service_constant, size });
}

function getNewestElements() {
  return (dispatch) => {
    dispatch(request());

    elementsService.getNewestElements().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(
          alertActions.error(`${error.error.message || error.error}`, 'Fehler')
        );
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: elementsConstants.ALL_REQUEST };
  }
  function success(data) {
    return { type: elementsConstants.ALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: elementsConstants.ALL_FAILURE, error };
  }
}

function update(form) {
  return (dispatch) => {
    if (form.replaced === true) {
      // upload first, then update

      dispatch(begin_upload());

      elementsService
        .upload(form.replaced_file.file, (p) => {
          let progress = (p.loaded * 100.0) / p.total;
          dispatch(progress_upload(progress));
        })
        .then((response) => {
          const data = response.data;
          dispatch(finished_upload(data));
          dispatch(
            defaults.update({
              form: {
                ...form,
                replaced_file: {
                  filename: data.filename,
                  real_filename: data.real_filename,
                  content_type: data.content_type,
                },
                replaced_resolution: form.replaced_resolution,
              },
              ...service_constant,
              success_message: 'Element wurde gespeichert.',
              error_message: 'Element konnte nicht gespeichert werden.',
            })
          );
        });
    } else {
      // no new file...
      dispatch(
        defaults.update({
          form,
          ...service_constant,
          success_message: 'Element wurde gespeichert.',
          error_message: 'Element konnte nicht gespeichert werden.',
        })
      );
    }
  };
}

function get_statistics() {
  return (dispatch) => {
    dispatch({ type: elementsConstants.GET_STATISTICS_SAGA });
  };
}

function remove(id) {
  return defaults.remove({
    id,
    ...service_constant,
    success_message: 'Element wurde gelöscht.',
    error_message: 'Element konnte nicht gelöscht werden.',
    success_callback: (dispatch) => {
      dispatch(get_statistics());
    },
  });
}

function getAll(skip = 0, limit = 20, page = 1, filter = {}) {
  return (dispatch) => {
    dispatch({
      type: elementsConstants.ALL_REQUEST_SAGA,
      payload: { skip, limit, page, filter },
    });
  };
}

function showForm(element_id) {
  return (dispatch) => {
    dispatch(show_element_form(element_id));
  };

  function show_element_form(element_id) {
    return {
      type: 'SHOW_FORM',
      formType: 'ELEMENT_FORM',
      formProps: { id: element_id },
    };
  }
}

function newForm(element) {
  return (dispatch) => {
    dispatch(show_element_form(element));
  };

  function show_element_form(element) {
    return {
      type: 'SHOW_FORM',
      formType: 'ELEMENT_FORM',
      formProps: { isNew: true, element },
    };
  }
}

function newMultiUploadForm(element) {
  return (dispatch) => {
    dispatch(show_multi_upload_element_form(element));
  };

  function show_multi_upload_element_form(elements) {
    return {
      type: 'SHOW_FORM',
      formType: 'ELEMENT_FORM',
      formProps: { isNew: true, multipleUploads: true, element: elements },
    };
  }
}

function resetElementForm() {
  return (dispatch) => {
    dispatch(reset_element_form());
    //history.push('/new-screen');
  };

  function reset_element_form(_element) {
    return { type: elementsConstants.NEW_FAILURE };
  }
}

function createElement(form, file, resolution, next = []) {
  return (dispatch) => {
    dispatch(begin_upload());
    dispatch(request());
    elementsService
      .upload(file, (p) => {
        let progress = (p.loaded * 100.0) / p.total;
        dispatch(progress_upload(progress));
      })
      .then(
        (response) => {
          const data = response.data;
          dispatch(alertActions.success('Element wurde hochgeladen und wird jetzt vom Server verarbeitet...'));
          elementsService
            .create(
              form,
              {
                filename: data.filename,
                real_filename: data.real_filename,
                content_type: data.content_type,
                meta: data.meta,
              },
              resolution,
              resolution.width === 1280 && resolution.orientation === 1 ? 90 : 0
            )
            .then(
              (response) => {
                //toast.dismiss(toastId);
                dispatch(finished_upload(data));
                dispatch(alertActions.success('Element wurde erstellt.'));
                dispatch(success(response));
                dispatch(sideFormsActions.hideForm());
                if (next.length > 0) {
                  dispatch(elementsActions.newMultiUploadForm(next));
                }
                dispatch(get_statistics());
              },
              (error) => {
                dispatch(
                  alertActions.error(
                    error.error ? error.error : error,
                    'Fehler bei Element Erstellung'
                  )
                );
                dispatch(failure(error.error ? error.error : error));
                //toast.dismiss(toastId);
              }
            );
        },
        (error) => {
          //toast.dismiss(toastId);
          dispatch(failure(error.error));
        }
      );
  };

  function request() {
    return { type: elementsConstants.CREATE_REQUEST };
  }
  function success(data) {
    return { type: elementsConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: elementsConstants.CREATE_FAILURE, error };
  }
}

function begin_upload() {
  return { type: elementsConstants.UPLOAD_REQUEST };
}
function progress_upload(progress) {
  return { type: elementsConstants.UPLOAD_PROGRESS, progress };
}
function finished_upload(data) {
  return { type: elementsConstants.UPLOAD_SUCCESS, data };
}
