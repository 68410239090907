import { deviceConstants } from '../constants';
import { deviceService } from '../services';
import { alertActions } from './alert.actions';
import { modalActions } from './modal.actions';

import defaults from './default.actions';
import { locationActions } from './location.actions';

const service_constant = {
  service: deviceService,
  constants: deviceConstants,
};

export const deviceActions = {
  get,
  update,
  getAll,
  createNewDevice,
  checkDeviceKey,
  clearDeviceItemState,
  assignToLocation,
  getForLocation,
  showForm,
  remove,
  showAddFeature,
  showFeatureSettings,
  get_statistics,
  refresh_mem,
  assignMirrorToDevice,
};

function get(id) {
  return defaults.get({
    id,
    ...service_constant,
  });
}

function validate(form) {
  let errors = [];

  if (form.label.length < 2) {
    errors.push('Es muss ein Name angegeben werden');
  }

  if (
    form.stream &&
    form.stream.orientation !== form.orientation &&
    form.stream.orientation !== 2
  ) {
    errors.push(
      'Die Bildschirm Ausrichtung des Micast-Systems stimmt nicht mit der Ausrichtung des ausgewählten Streams überein!'
    );
  }

  return errors.length < 1 ? null : errors.join('<br/>');
}

function update(form) {
  let e = validate(form);
  if (e) {
    return (dispatch) => {
      dispatch(
        alertActions.error(e, 'Micast-System kann nicht gespeichert werden:')
      );
    };
  }

  (form.settings.features || []).map((feature) => {
    if (typeof feature.settings !== 'string') {
      feature.settings = JSON.stringify(feature.settings);
    }

    return feature;
  });

  if (form.settings) {
    form.settings.show_clock = form.settings.show_clock == true ? 1 : 0;
    form.settings.show_scrolltext = form.settings.show_scrolltext == true ? 1 : 0;
  }

  return defaults.update({
    form,
    ...service_constant,
    success_message: 'Micast-System Einstellungen wurden gespeichert.',
    error_message:
      'Micast-System einstellungen konnten nicht gespeichert werden.',
  });
}

function getAll({ filters, sortBy, pageSize, pageIndex, search }) {
  return (dispatch) => {
    dispatch(request());
    deviceService.getAll({ filters, sortBy, pageSize, pageIndex, search }).then(
      (data) => dispatch(success(data.data)),
      (error) => dispatch(failure(error.error))
    );
  };

  function request() {
    return { type: deviceConstants.GETALL_REQUEST };
  }
  function success(devices) {
    return { type: deviceConstants.GETALL_SUCCESS, devices };
  }
  function failure(error) {
    return { type: deviceConstants.GETALL_FAILURE, error };
  }
}

function createNewDevice(locationID, locationName) {
  return (dispatch) => {
    dispatch(
      locationActions.getForSelection(null, (c) => {
        if (c.length > 0) {
          deviceService.preAssign().then(
            () =>
              dispatch({
                type: 'SHOW_MODAL',
                modalType: 'CREATE_DEVICE',
                modalProps: { locationID, locationName },
              }),
            (error) => {
              dispatch({
                type: 'SHOW_MODAL',
                modalType: 'NO_FREE_LICENCES',
              });
              dispatch(
                alertActions.error(
                  `${error.error.message || error.error}`,
                  'Fehler'
                )
              );
            }
          );
        } else {
          dispatch({
            type: 'SHOW_MODAL',
            modalType: 'NO_LOCATION_WARNING',
          });
        }
      })
    );
  };
}

function checkDeviceKey(key) {
  return (dispatch) => {
    dispatch(request());
    deviceService.checkKey(key).then(
      (data) => dispatch(success(data.data)),
      (error) => dispatch(failure(error.error))
    );
  };

  function request() {
    return { type: deviceConstants.CHECK_KEY_REQUEST };
  }
  function success(device) {
    return { type: deviceConstants.CHECK_KEY_SUCCESS, device };
  }
  function failure(error) {
    return { type: deviceConstants.CHECK_KEY_FAILURE, error };
  }
}

function assignToLocation(location_id, key, label, orientation) {
  return (dispatch) => {
    dispatch(request());
    deviceService.assignToLocation(location_id, key, label, orientation).then(
      (data) => {
        dispatch(alertActions.success('Neues Gerät wurde zugewiesen'));
        dispatch(success(data.data));
        dispatch(modalActions.hideModal());
      },
      (error) => dispatch(failure(error.error))
    );
  };

  function request() {
    return { type: deviceConstants.ASSIGN_TO_LOCATION_REQUEST };
  }
  function success(device) {
    return { type: deviceConstants.ASSIGN_TO_LOCATION_SUCCESS, device };
  }
  function failure(error) {
    return { type: deviceConstants.ASSIGN_TO_LOCATION_FAILURE, error };
  }
}

/*deviceActions.assignMirrorToDevice(
        Number(propsMirrorServerDeviceID),
        stateItem.key,
        stateItem.label,
        stateItem.orientation
      )*/

function assignMirrorToDevice(
  mirror_server_device_id,
  key,
  label,
  orientation
) {
  return (dispatch) => {
    dispatch(request());
    deviceService
      .assignMirrorToDevice(mirror_server_device_id, key, label, orientation)
      .then(
        (data) => {
          dispatch(alertActions.success('Mirror-Device wurde zugewiesen'));
          dispatch(success(data.data));
          dispatch(modalActions.hideModal());
        },
        (error) => dispatch(failure(error.error))
      );
  };

  function request() {
    return { type: deviceConstants.ASSIGN_MIRROR_TO_DEVICE_REQUEST };
  }
  function success(device) {
    return { type: deviceConstants.ASSIGN_MIRROR_TO_DEVICE_SUCCESS, device };
  }
  function failure(error) {
    return { type: deviceConstants.ASSIGN_MIRROR_TO_DEVICE_FAILURE, error };
  }
}

function getForLocation(location_id) {
  return (dispatch) => {
    dispatch(request(location_id));
    deviceService.getForLocation(Number(location_id)).then(
      (data) => {
        dispatch(success(data.data, Number(location_id)));
      },
      (error) => dispatch(failure(error.error))
    );
  };

  function request(location_id) {
    return {
      type: deviceConstants.GET_DEVICES_FOR_LOCATION_REQUEST,
      location_id,
    };
  }
  function success(devices, location_id) {
    return {
      type: deviceConstants.GET_DEVICES_FOR_LOCATION_SUCCESS,
      devices,
      location_id,
    };
  }
  function failure(error) {
    return { type: deviceConstants.GET_DEVICES_FOR_LOCATION_FAILURE, error };
  }
}

function clearDeviceItemState() {
  return (dispatch) => {
    dispatch({
      type: deviceConstants.CLEAR_STATE,
    });
  };
}

function showForm(id) {
  return defaults.show_side_form({
    id,
    name: 'MICAST_FORM',
  });
}

/*function showForm(id) {
  return (dispatch) => {
    dispatch(show_form(id));
  };

  function show_form(id) {
    return { type: 'SHOW_FORM', formType: 'MICAST_FORM', formProps: { id } };
  }
}*/

function showAddFeature(onSelectFeature) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'ADD_FEATURES',
      modalProps: { onSelectFeature },
    });
  };
}

function showFeatureSettings(name, current_settings, onSubmit) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'FEATURE_SETTINGS',
      modalProps: { name, current_settings, onSubmit },
    });
  };
}

function remove(id) {
  return defaults.remove({
    id,
    ...service_constant,
    success_message: 'Gerät wurde gelöscht.',
    error_message: 'Gerät konnte nicht gelöscht werden.',
  });
}

function get_statistics() {
  return (dispatch) => {
    dispatch(request());

    deviceService.get_statistics().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.error));
      }
    );
  };

  function request() {
    return { type: deviceConstants.GET_STATISTICS_REQUEST };
  }
  function success(data) {
    return { type: deviceConstants.GET_STATISTICS_SUCCESS, data };
  }
  function failure(error) {
    return { type: deviceConstants.GET_STATISTICS_FAILURE, error };
  }
}

function refresh_mem(device_id, mirror_server_id = 0) {
  return (dispatch) => {
    dispatch(request());
    deviceService.refresh_mem(device_id, mirror_server_id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => dispatch(failure(error.error))
    );
  };

  function request() {
    return { type: deviceConstants.REFRESH_MEM_REQUEST };
  }
  function success(data) {
    return { type: deviceConstants.REFRESH_MEM_SUCCESS, data };
  }
  function failure(error) {
    return { type: deviceConstants.REFRESH_MEM_FAILURE, error };
  }
}
