export const sideFormsActions = {
  hideForm
};

function hideForm() {
  return dispatch => {
    dispatch({
      type: "HIDE_FORM"
    });
  };
}