import { authHeader } from '../helpers';
import { userService } from './user.service';
export const organisationService = {
  getAll,
  getForFilter,
};

function getForFilter(search) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ search }),
  };

  return fetch(
    `${process.env.SERVER_URL}/organisations/filter`,
    requestOptions
  ).then(handleResponse);
}

function getAll({ filters, sortBy, pageSize, pageIndex, search }) {
  if (sortBy && sortBy.length > 0) {
    sortBy = sortBy.map(function (item) {
      return (
        item.id.replace('details.', '') + ' ' + (item.desc ? 'DESC' : 'ASC')
      );
    });
  }
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ filters, sortBy, pageSize, pageIndex, search }),
  };

  return fetch(`${process.env.SERVER_URL}/organisations`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.status === false) {
        const error = data.message;
        return Promise.reject(error);
      }
    }

    return data;
  });
}
