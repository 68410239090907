import { settingsConstants } from '../constants';

const initialState = {
  loading: false,
  item: null,
  items: [],
  saved: true,
  is_saving: false,
  error: false,
  error_message: '',
};

export function settings(state = initialState, action) {
  switch (action.type) {
    case settingsConstants.GET_REQUEST:
      return {
        ...state,
        item: null,
        loading: true,
      };
    case settingsConstants.GET_SUCCESS: 
    {
      const data = action.data.data;
      let settings = {};
      data.forEach((setting) => {
        if (!settings[setting.key]) {
          settings[setting.key] = {};
        }

        if (!settings[setting.key][setting.sub_key]) {
          settings[setting.key][setting.sub_key] = {};
        }

        let value;
        switch (setting.type_of_value) {
          case 'number':
            value = parseInt(setting.value);
            break;
          case 'bool':
            value = setting.value === '1';
            break;
          default:
            value = setting.value;
        }

        settings[setting.key][setting.sub_key] = value;
      });

      return {
        ...state,
        item: settings,
        loading: false,
      };
    }
    case settingsConstants.GET_FAILURE:
      return {
        ...state,
        item: null,
        loading: false,
        error: true,
        error_message: action.message,
        // later add error message here
      };

    case settingsConstants.UPDATE_REQUEST:
      return {
        ...state,
        is_saving: true,
        saved: false,
        error: false,
        error_message: '',
      };
    case settingsConstants.UPDATE_SUCCESS: {
      let items = state.items;

      items.forEach((item, index) => {
        if (item.model.ID === action.data.data.model.ID) {
          items[index] = action.data.data;
        }
      });

      return {
        ...state,
        item: action.data.data,
        items,
        is_saving: false,
        saved: true,
      };
    }
    case settingsConstants.UPDATE_FAILURE:
      return {
        ...state,
        is_saving: false,
        saved: false,
        error: true,
        error_message: action.message,
      };

    default:
      return state;
  }
}
